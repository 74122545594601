/**
 * ParkListing - list search view
 */

import Icon from 'components/Icon';
import React, { useState } from 'react';
import Masonry from 'react-masonry-component';
import { ParkModel } from 'types/global';
import Park from './park';

type Props = {
	/** A list of parks  */
	parks: ParkModel[];
	searchPlaceholderText: string;
	submitText: string;
};

/** Park listing listsearchview component. */
const ListSearchView: React.FC<Props> = ({
	parks,
	searchPlaceholderText,
	submitText,
}) => {
	const [query, setQuery] = useState('');
	const handleOnChange = (e: any) => setQuery(e.target.value);
	const handleSubmit = (e: any) => {
		e.preventDefault();
		setQuery(query);
	};

	return (
		<div>
			<form method="POST" className="relative w-full" onSubmit={handleSubmit}>
				<label className="sr-only">{submitText}</label>

				<input
					type="text"
					value={query}
					placeholder={searchPlaceholderText}
					onChange={handleOnChange}
					className="w-full h-8 px-5 placeholder-primary text-xl shadow-top rounded-lg"
				/>
				<button
					type="submit"
					aria-label={submitText}
					className="search-icon top-1"
				>
					<Icon
						icon="search"
						aria-hidden="true"
						direction="down"
						className="text-gray-600"
					/>
				</button>
			</form>

			<div className="md:max-w-site lg:max-w-full md:mx-auto lg:mx-20 flex justify-center my-4">
				{/* Replacement for Grid component since it doesn't satisfy customer req to handle resizing */}
				<Masonry
					options={{
						columnWidth: 268,
						gutter: 16,
						fitWidth: true,
						resize: true,
					}}
				>
					{parks
						.filter((p: ParkModel) =>
							p.name?.toLowerCase().includes(query.toLowerCase())
						)
						.map((park: ParkModel, index: number) => (
							<div
								key={`${park.imageMapCoordinates}_${index}`}
								className="mb-4"
							>
								<Park {...park} />
							</div>
						))}
				</Masonry>
			</div>
		</div>
	);
};

export default ListSearchView;
