/**
 * Crumb
 */

import React from 'react';
import Link from 'components/Link';

interface Props {
	/** Link href */
	href: string;

	/** If it's the currently active breadcrumb */
	active?: boolean;
}

/** Crumb component */
const Crumb: React.FC<Props> = ({ href, active, children }) => (
	<li className={!active ? 'crumb' : ''}>
		{active ? (
			<span>{children}</span>
		) : (
			<Link href={href} className="hover:underline">
				{children}
			</Link>
		)}
	</li>
);

export default Crumb;
