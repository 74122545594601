/**
 * MobileMenu
 */

import React from 'react';
import Ul from './MobileMenu.Ul';

interface Props {
	accessibilityDescription: string;
	openNavigationItemLabel?: string;
	closeNavigationItemLabel?: string;

	/** Array of menu items */
	items: any[];

	/** Callback method when the menu closes */
	onClose?: any;

	/** Callback method when user clicks in a link */
	onLinkClick?: any;
}

/** Mobile menu. */
const MobileMenu: React.FC<Props> = ({
	accessibilityDescription,
	items,
	onClose,
	onLinkClick,
	openNavigationItemLabel,
	closeNavigationItemLabel,
}) => (
	<nav aria-label={accessibilityDescription}>
		<div tabIndex={-1}>
			<Ul
				links={items}
				level={1}
				onClose={onClose}
				onLinkClick={onLinkClick}
				openNavigationItemLabel={openNavigationItemLabel}
				closeNavigationItemLabel={closeNavigationItemLabel}
			/>
		</div>
	</nav>
);

export default MobileMenu;
