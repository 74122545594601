/**
 * Carousel
 */

import React from 'react';
import { ICarousel } from 'types/global';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselToggleFullScreen from './CarouselToggleFullscreen';
import CarouselNormal from './CarouselNormal';

/** Image carousel. */
const Carousel: React.FC<ICarousel> = ({
	slides,
	fullscreenOpenLabel,
	fullscreenCloseLabel,
	fullScreenMode,
}) =>
	fullScreenMode ? (
		<CarouselToggleFullScreen
			slides={slides}
			fullscreenCloseLabel={fullscreenCloseLabel}
			fullscreenOpenLabel={fullscreenOpenLabel}
		/>
	) : (
		<CarouselNormal slides={slides} />
	);

export default Carousel;