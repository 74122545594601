import StartPage from './StartPage';
import ErrorPage404 from './ErrorPage404';
import ArticlePage from './ArticlePage';
/* PLOP_INJECT_IMPORT */
import ResetPasswordPage from './ResetPasswordPage';
import ActivitiesPage from './ActivitiesPage';
import ParkListingPage from './ParkListingPage';
import ParkPage from './ParkPage';
import SearchPage from './SearchPage';
import FormContainerPage from './FormContainerPage';
import PreviewBlockPage from './PreviewBlockPage';
import { ContentTypes } from 'types/pages';

const Pages: any = {
	SNPStartPage: StartPage,
	SNPErrorPage404: ErrorPage404,
	SNPArticlePage: ArticlePage,
	/* PLOP_INJECT_PAGE */
	SNPResetPasswordPage: ResetPasswordPage,
	SNPActivityListingPage: ActivitiesPage,
	SNPParkListingPage: ParkListingPage,
	SNPParkPage: ParkPage,
	SNPSearchPage: SearchPage,
	FormContainerPage,
	PreviewBlockPage,
};

export const PagesMapper = (pageContent: ContentTypes | undefined) => {
	if (pageContent) {
		let page: any = Pages[pageContent.modelType];

		if (page) {
			return page;
		}
	}

	return ErrorPage404;
};
