import React, { useRef } from 'react';
import Image from 'components/Image';
import { InstagramImage, InstagramVideo } from 'types/global';

interface Props {
	content: InstagramImage | InstagramVideo | null;
	goToUser: string;
}

const InstagramModal: React.FC<Props> = ({
	content,
	goToUser,
}) => {
	const btnRef = useRef<HTMLButtonElement>(null);

	React.useEffect(() => {
		if (btnRef.current) {
			btnRef.current.focus();
		}
	}, []);

	return (
		<div className="relative flex-col justify-center items-center bg-white p-6">
			{content !== undefined &&
				(content?.modelType === 'InstagramImage' ? (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={content.permalink}
						aria-label={goToUser}
					>
						<Image src={content.url} className="object-cover img-h-ig-modal" />
					</a>
				) : (
					<iframe
						title={content?.caption}
						height="600px"
						width="100%"
						allowFullScreen
						src={content?.url}
					/>
				))}
			<div className="my-4">
				<p className="text-sm">
					<a className="text-sm underline" href={content?.userUrl}>
						{content?.userName}
					</a>{' '}
					| {content?.caption}
				</p>
			</div>
		</div>
	);
};

export default InstagramModal;
