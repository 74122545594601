import React from 'react';
import { useSelector } from 'react-redux';
import { selectTopLinks } from 'store/modules/model';

import { TopLinks as Component, TopLinksForMobile } from './TopLinks';

interface Props {
	forMobile?: boolean;
}

const TopLinks: React.FC<Props> = ({ forMobile }) => {
	const topLinks: any = useSelector(selectTopLinks);

	if (forMobile) {
		return (
			<>
				{topLinks && topLinks.topLinks.length > 0 && (
					<TopLinksForMobile {...topLinks} />
				)}
			</>
		);
	}

	return (
		<>
			{topLinks && topLinks.topLinks.length > 0 && <Component {...topLinks} />}
		</>
	);
};

export default TopLinks;
