/**
 * ImageDesctiption
 */

import React from 'react';

interface Props {
	/** Description text */
	description?: string;
	_properties?: any;
}

/** Image description component (used by Image component). */
const ImageDesctiption: React.FC<Props> = ({
	description,
	_properties = {},
}) => {
	return (
		<>
			<div className="absolute bottom-0 left-0 w-full">
				<p
					{..._properties}
					className="py-3 px-3 w-full text-transparent hover:text-white hover:bg-black-60"
				>
					{description}
				</p>
			</div>
		</>
	);
};

export default ImageDesctiption;
