/**
 * MobileMenu Li
 */

import React, { useEffect, useState } from 'react';
import Ul from './MobileMenu.Ul';
import { Link as LinkType, MenuLinkVariant } from 'types/global';
import clsx from 'clsx';
import Link from 'components/Link';
import Icon from 'components/Icon';
import { px2rem } from 'theme/utils';

interface LiProps {
	/** A menu link structured as JSON */
	link: LinkType;

	/** The link level (1, 2, 3) */
	level: number;

	/** Link item index */
	index: number;

	/** Variant of the link */
	variant?: MenuLinkVariant;

	/** Determine if childelement or not for indent */
	isChild?: boolean;

	/** Screenreadertext for mobile (toggle open) */
	openNavigationItemLabel?: string;

	closeNavigationItemLabel?: string;

	/** Callback method when the menu closes */
	onClose?: any;

	/** Callback method when user clicks in a link */
	onLinkClick?: any;
}

/** A nav item (li) used by MobileMenu */
const Li: React.FC<LiProps> = ({
	link,
	level,
	variant,
	index,
	onClose,
	onLinkClick,
	openNavigationItemLabel,
	closeNavigationItemLabel,
}) => {
	const linkLevel = variant === 'headerLink' ? 2 : level;
	const [activeChildren, setActiveChildren] = useState(false);
	const [child, setChild] = useState<any>(null);

	const setActiveChildrens = (link: LinkType) => {
		const childLinks = link.items || [];
		let links: LinkType[] = [];

		if (level >= 2) {
			links = [...childLinks];
		} else {
			links = childLinks;
		}

		setChild(
			<Ul
				openNavigationItemLabel={openNavigationItemLabel}
				closeNavigationItemLabel={closeNavigationItemLabel}
				isChild
				level={level + 1}
				links={links}
				onClose={onClose}
				onLinkClick={onLinkClick}
			/>
		);
	};

	const handleSetActiveChildren = (e: MouseEvent, link: LinkType) => {
		e.preventDefault();
		if (!activeChildren) {
			setActiveChildrens(link);
		} else {
			setChild(null);
		}

		setActiveChildren(!activeChildren);
	};

	useEffect(() => {
		const doesLinkHaveChildren = link.items && link.items.length > 0;
		const isLinkChildActive = doesLinkHaveChildren
			? link.items?.some((i) => i.isActive)
			: false;
		if (link && doesLinkHaveChildren && isLinkChildActive) {
			setActiveChildrens(link);
		}
	}, [link]);

	return (
		<li>
			<div
				className={clsx(
					'flex',
					'items-center',
					'justify-between',
					'h-8',
					'pr-2',
					index > 0 && 'border-t border-black-25'
				)}
				style={{
					paddingLeft: linkLevel > 1 ? `${px2rem(linkLevel * 10)}` : '0',
				}}
			>
				<Link
					onClick={onLinkClick}
					href={link.url}
					external
					className={clsx(
						'hover:text-yellow mr-4 ',
						link.isActive && 'text-yellow'
					)}
				>
					{link.text}
				</Link>
				{link.items && link.items.length > 0 && (
					<button onClick={(e: any) => handleSetActiveChildren(e, link)}>
						<label className="sr-only">{`${openNavigationItemLabel} ${link.text}`}</label>
						<Icon direction={child ? 'down' : 'up'} icon="chevron" size={1} />
					</button>
				)}
			</div>

			{child && child}
		</li>
	);
};

export default Li;
