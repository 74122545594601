/**
 * StartPage
 */

import Carousel from 'components/Carousel';
import EpiContentArea from 'components/EpiContentArea';
import EpiFragments from 'components/EpiFragments';
import Link from 'components/Link';
import ReadSpeaker from 'components/ReadSpeaker';
import Share from 'components/Share';
import Text from 'components/Text';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectReadSpeaker } from 'store/modules/model';
import { StartPageModel } from 'types/pages';
import { selectMetadata } from 'store/modules/model';


/** Page template for startpage. */
const StartPage: React.FC<StartPageModel> = ({
	heading,
	contentArea,
	preambleHtml,
	aboutLink,
	carousel,
	_properties = {},
}) => {
	const readSpeaker = useSelector(selectReadSpeaker);
	const metaData = useSelector(selectMetadata);

	return (
		<>
			{carousel && <Carousel {...carousel} />}
			
			<div className="md:relative sm:w-full">
				<ReadSpeaker url={readSpeaker?.url} text={readSpeaker?.text} />

				<Text as="h1" className="sr-only">
					{metaData?.title}
				</Text>

				<div className="rssnp-readarea width-container text-center preamble-text-width mb-8 mt-4 mx-auto">
					<Text
						as="h2"
						styleAs="xl2"
						{..._properties?.heading}
						className="leading-tight"
					>
						{heading}
					</Text>

					<div>
						<EpiFragments
							{..._properties?.preambleHtml}
							fragments={preambleHtml?.fragments}
						/>
					</div>

					{aboutLink && (
						<Link
							href={aboutLink.url}
							className="arrow-link-black text-lg hover:underline"
						>
							{aboutLink.text}
						</Link>
					)}
				</div>
			</div>

			<EpiContentArea {..._properties?.contentArea} content={contentArea} />

					<Share />
		</>
	);
};

export default StartPage;
