/**
 * Instagram
 */

import React, {
	useState,
	useRef,
	useEffect,
} from 'react';
import { Cell, Grid } from 'components/Grid';
import { InstagramModel } from 'types/blocks';
import clsx from 'clsx';
import { InstagramImage, InstagramVideo } from 'types/global';
import { useMediaQuery } from 'react-responsive';
import Image from 'components/Image';
import { useModal } from 'hooks/useModal';
import Button from 'components/Button';
import Axios from 'axios';
import InstagramModal from './InstagramModal';
import EpiFragments from 'components/EpiFragments';

/**
 * The main instagram integration for frontend
 */

const Instagram: React.FC<InstagramModel> = ({
	heading,
	text,
	posts,
	pagination,
	buttonText,
	url,
	apiUrl,
	imageAlt,
	colorTheme,
	closeModal: closeModalText,
	goToUser,
	_properties,
}) => {
	/* Statehandling */
	const [content, setContent] = useState<
		InstagramImage | InstagramVideo | null
	>(null);
	const [instagramFeed, setInstagramFeed] = useState<
		(InstagramImage | InstagramVideo)[]
	>(posts || []);
	const {Modal, openModal} = useModal();
	const itemsRef = useRef<Array<HTMLButtonElement | null>>([]);
	const [instagramBtnId, setInstagramBtnId] = useState<number>(-1);
	const [pageIsLoaded, setPageIsLoaded] = useState<boolean>(false);

	const currentPage =
		pagination?.currentPage !== undefined && pagination.currentPage >= 1
			? pagination?.currentPage
			: 1;

	const [page, setPage] = useState<number>(currentPage);
	const [disabled, setDisabled] = useState<boolean>(false);

	useEffect(() => {
		if (instagramBtnId >= 0) {
			for (let i = 0; i < itemsRef.current.length; i++) {
				if (i === instagramBtnId) {
					/* tslint:disable-next-line */
					// @ts-ignore: Unreachable code error
					itemsRef.current[instagramBtnId]?.focus();
				}
			}
		}
	}, [openModal]);

	useEffect(() => {
		const setPageLoaded = () => {
			setPageIsLoaded(true);
		}

		if(document.readyState === "complete"){
			setPageLoaded();
		}else{
			window.addEventListener('load', setPageLoaded);
			return () => window.removeEventListener('load', setPageLoaded);
		}
	},[])

	/* functions */

	const handleClick = (
		content: InstagramVideo | InstagramImage,
		id: number
	) => {
		
		setInstagramBtnId(id);
		setContent(content);
	};

	const isTablet = useMediaQuery({ query: '(max-width: 767px)' });

	const getFeed = async () => {
		try {
			const { data } = await Axios.get(`${apiUrl}`, {
				params: {
					itemsPerPage: pagination?.itemsPerPage || 8,
					offset: page,
				},
			});

			if (data.length !== pagination?.itemsPerPage) {
				setDisabled(true);
			}
			let newFeed: (InstagramImage | InstagramVideo)[] = [
				...instagramFeed,
				...data,
			];

			setInstagramFeed(newFeed);
			setPage((state) => state + 1);
		} catch (err) {
			console.error(err);
			setPage(1);
		}
	};

	return (
		<Grid
			maxWidth
			padding={false}
			margin={false}
			className={clsx(
				"w-full pt-10",
				colorTheme === "grey-lighter" ? "bg-grey-lighter" : "bg-white"
			)}>
			<Cell span={12} className="-mb-4">
				<div className="flex-col text-center">
					<h3 {..._properties?.heading} className="text-3xl font-bold my-3">
						{heading}{' '}
					</h3>

					{text && (
						<div className="fled-col md:flex md:justify-center md:items-center instagramblocktext">
							<EpiFragments
								{..._properties?.text}
								fragments={text?.fragments}
								isTablet={isTablet}
							/>
						</div>
					)}
				</div>
			</Cell>
			<div className="md:max-w-screen-xl grid md:grid-cols-4 gap-2 md:gap-0 items-start my-0 mx-auto p-4 ">
				{instagramFeed && pageIsLoaded &&
					instagramFeed.map((content: InstagramImage | InstagramVideo, key) => {
						const isImage = content.modelType === 'InstagramImage';
						const alt = `${imageAlt} ${key + 1}`;
						return (
							<Cell
								className="cursor-pointer md:m-3 hover:shadow-3xl "
								key={content.id}
								margin={false}
							>
								{isTablet ? (
									<a
										className="focus:outline-solid-orange h-full"
										target="_blank"
										rel="noopener noreferrer"
										href={content.permalink}
									>
										<Image
											className="md:my-1 md:inline-block object-cover"
											src={isImage ? content.url : content.thumbnailUrl}
											alt={alt}
											loading="lazy"
											decoding="async"
											fetchpriority="low"
											isVideo={!isImage}
										/>
									</a>
								) : (
									<button
										ref={(btn) => (itemsRef.current[key] = btn)}
										className="relative focus:outline-solid-orange h-full"
										onClick={() => {
											handleClick(content, key)
											openModal();
										}}
										title={content.caption}
										id={key.toString()}
									>
										<Image
											className="px-3 md:px-0 z-auto object-cover md:inline-block"
											src={isImage ? content.url : content.thumbnailUrl}
											alt={alt}
											isVideo={!isImage}
											loading="lazy"
											decoding="async"
											fetchpriority="low"
										/>
									</button>
								)}
							</Cell>
						);
					})}
			</div>
			{url ? (
				<Cell span={12} className="flex justify-center my-8">
					<Button as="a" className="hover:border-grey-dark" href={url}>
						{buttonText}
					</Button>
				</Cell>
			) : (
				<Cell span={12} className="flex justify-center my-8">
					{pagination && (
						<Button
							onClick={getFeed}
							className="hover:border-grey-dark"
							disabled={disabled}
						>
							{buttonText}
						</Button>
					)}
				</Cell>
			)}
			<Modal noModalPadding>
				<InstagramModal
					content={content}
					goToUser={goToUser || ''}
				/>
			</Modal>
		</Grid>
	);
};

export default Instagram;
