/**
 * InfoBlock
 */

import clsx from 'clsx';
import Image from 'components/Image';

import React, { useState } from 'react';
import type { IImage } from 'types/global';

interface Props {
	/** Block heading */
	heading: string;

	/** Block image */
	image: IImage;

	/** Block date */
	date?: string;

	/** Block text */
	text?: string;

	layout?: 'horizontal' | 'vertical';
}

/** InfoBlock component. */
const InfoBlock: React.FC<Props> = ({
	heading,
	image,
	date,
	text,
	layout = 'vertical',
}) => {
	const [imageHeight, setImageHeight] = useState<string>();
	const handleImageLoad = (event: any) => {
		const heightAllow = 270;
		if (heightAllow < event.target.clientHeight) {
			setImageHeight('w-auto');
		} else {
			setImageHeight('w-full');
		}
	};

	return (
		<article
			aria-labelledby="infoblock_heading"
			className={clsx(
				'bg-white p-5 border text-left h-full flex-col sm:flex-row sm:gap-5 ',
				layout === 'horizontal' && 'flex '
			)}
		>
			{image && (
				<Image 
					src={image.src}
					srcSet={image.srcSet}
					srcSetSizes={image.srcSetSizes}
					alt={image.alt}
					loading="lazy"
					aria-hidden="true"
					onLoad={handleImageLoad}
					className={clsx(
						layout === 'vertical' && 'mb-3 w-full',
						imageHeight
					)}
				/>
			)}
			<div
				className={clsx(
					layout === 'horizontal' && 'block sm:pr-2',
					'sm:w-full'
				)}
			>
				<h3
					className={clsx(
						'font-bold my-4 sm:mb-4 sm:mt-0 breaking-words',
						layout === 'vertical' && 'text-xl mb-3',
						layout === 'horizontal' && 'text-xl'
					)}
					id="infoblock_heading"
				>
					{heading}
				</h3>

				{date && (
					<p
						className={clsx(
							'font-standard mb-2 text-left breaking-words',
							layout === 'vertical' && 'text-sm',
							layout === 'horizontal' && 'text-base'
						)}
					>
						{date}
					</p>
				)}

				{text && (
					<p className={clsx('font-standard mb-2 text-left text-sm breaking-words')}>{text}</p>
				)}
			</div>
		</article>
	);
};

export default InfoBlock;
