/**
 * StandardPuffBlock
 */

import React from 'react';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Link from 'components/Link';
import clsx from 'clsx';
import type { IImage } from 'types/global';
import Image from 'components/Image';

interface Props {
	facebookLinkText: string;
	facebookTeaserText: string;
	facebookLink: string;
	colorTheme: string;
	iconImage: IImage;
	teaserImage: string;
	subscribeEmail: string;
	subscribeEmailSubject: string;
	subscribeEmailLinkText: string;
	unsubscribeEmailSubject: string;
	unsubscribeEmailLinkText: string;
}

/** Standard puff block component. */
const NewsletterPuff: React.FC<Props> = ({
	facebookLinkText,
	facebookTeaserText,
	facebookLink,
	iconImage,
	teaserImage,
	subscribeEmail,
	subscribeEmailSubject = 'Prenumerera på vårt nyhetsbrev',
	subscribeEmailLinkText,
	unsubscribeEmailSubject = 'Avsluta prenumeration',
	unsubscribeEmailLinkText,
	colorTheme,
}) => {
	return (
		<>
			{subscribeEmail && (
				<div className="bg-black">
					<div
						className="newsletter"
						style={{
							backgroundImage: `url(${teaserImage}?aimgsz=w300)`,
						}}
					>
						<Text as="h2" className="newsletter-title">
							{subscribeEmailSubject}
						</Text>
					</div>
					<div className="input-wrapper">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`mailto:${subscribeEmail}?subject=${subscribeEmailSubject}`}
							title={subscribeEmailSubject}
							className="link-subscribe"
						>
							{subscribeEmailLinkText}
						</a>

						<a
							target="_blank"
							rel="noopener noreferrer"
							title={unsubscribeEmailSubject}
							className="arrow-link link-unsubscribe"
							href={`mailto:${subscribeEmail}?subject=${unsubscribeEmailSubject}`}
						>
							{unsubscribeEmailLinkText}
						</a>
					</div>
				</div>
			)}
			{facebookTeaserText && facebookLink && (
				<div className={`bg-${colorTheme}`}>
					<div className="text-center max-w-2xl py-16 mx-auto">
						<Link
							href={facebookLink}
							title={facebookLinkText}
							className="focus:outline-solid-orange block"
						>
							<div className="share-list">
								{iconImage && (
									<div className={clsx('mx-auto mb-3 rounded w-15')}>
										<Image 
										className="mx-auto"
										src={iconImage.src}
										srcSet={iconImage.srcSet}
										srcSetSizes={iconImage.srcSetSizes}
										alt={iconImage.alt}
										aria-hidden="true"
										/>
									</div>
								)}

								<Text as="p" className="facebook-text">
									<Icon
										size={0}
										direction={'left'}
										icon={'chevron'}
										aria-hidden="true"
									/>{' '}
									{facebookTeaserText}
								</Text>
							</div>
						</Link>
					</div>
				</div>
			)}
		</>
	);
};

export default NewsletterPuff;
