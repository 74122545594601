import React from 'react';
import { LabelProps, ErrorSpanProps } from './types';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip';

export const Label: React.FC<LabelProps> = ({
	htmlFor,
	required,
	children,
	helpText,
}) => {
	return (
		<label
			className="flex font-medium mb-4 mt-16 text-lg leading-5"
			htmlFor={htmlFor}
		>
			{children}
			{required && <abbr title="Obligatoriskt">*</abbr>}
			{helpText && (
				<Tooltip
					text={helpText}
					containerStyle="bg-yellow rounded-3xl h-6 w-6 cursor-pointer flex items-center justify-center ml-2"
					toolTipStyle="h-16 w-56 border bg-grey-lighter text-gray-700 text-sm p-2"
				>
					?
				</Tooltip>
			)}
		</label>
	);
};

export const ErrorSpan: React.FC<ErrorSpanProps> = ({
	fieldId,
	invalid,
	children,
	className,
	...props
}) => (
	<span
		className={clsx(className, 'block', 'text-red-600', 'mt-2')}
		id={`form${fieldId}__desc`}
		aria-live="polite"
		hidden={!invalid}
		{...props}
	>
		{children}
	</span>
);
