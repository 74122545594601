/**
 * FormContainerPage
 */

import React from 'react';

import Layout from 'layouts';
import EpiForms from 'components/EpiForms';
import { EpiFormsProps } from 'components/EpiForms/EpiForms';

import { Grid, Cell } from 'components/Grid';

/** Main description for this component. */
const FormContainerPage: React.FC<EpiFormsProps> = (props) => {
	return (
		<Layout>
			<Grid>
				<Cell span={0} tablet={2} desktop={2}>
					&nbsp;
				</Cell>
				<Cell span={7}>{props.fields && <EpiForms {...props} />}</Cell>
			</Grid>
		</Layout>
	);
};

export default FormContainerPage;
