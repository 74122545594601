/**
 * ActivityInfo
*/
import React from 'react';

import Button from 'components/Button';
import { useModal } from 'hooks/useModal';
import Image from 'components/Image';
import Link from 'components/Link';
import type { IImage } from '../../types/global';
import { useSelector } from 'react-redux';
import { selectEpi } from 'store/modules/epi';
import Text from 'components/Text';
import EditableField from 'components/EditableField';


export interface ActivityInfo {
	/** If there is bookinginfo availble, this is it. */
	booking?: {
		link: string;
		linkText: string;
		heading: string;
		linkCaption: string;
		text: string;
		buttonText: string;
	} | null;
	/** Array of info objects,  */

	/** Epi properties to use for on page editing */
	onPageEditHtmlAttributes: any;

	when: {
		startDate: string | null;
		stopDate: string | null;
		wholeYearText: string;
		title: string;
		text: string | null;
	};
	location: {
		locationImage: IImage | null,
		locationName: string | null,
		locationLatitude: string | null,
		locationLongitude: string | null,
		title: string,
		text: string | null
	}

	specialEquipment: {
		title: string;
		text: string | null;
	};
	otherInformation: {
		title: string;
		text: string | null;
	};
	
}

/** Main description for this component. */
const ActivityInfo: React.FC<ActivityInfo> = ({
	booking = null,
	when,
	location,
	specialEquipment,
	otherInformation,
	onPageEditHtmlAttributes = {}
}) => {
	const {Modal, openModal} = useModal();

	//const toggle = setShowModal as Dispatch<SetStateAction<boolean>>;
    const { isEditable } = useSelector(selectEpi);

	const checkIfLocationHasLongLatImage = () => {
		return (location.locationImage && location.locationLatitude && location.locationLongitude);
	}

	const checkIfLocationHasTextOrName = () => {
		return (location.locationName || location.text);
	}

	const checkIfWhenHasStartDateAndWhenTextIsNull = () => {
		return (when.startDate && !when.text);
	}

	const checkIfWhenHasStopDateAndWhenTextIsNull = () => {
		return (when.stopDate && !when.text);
	}

	const checkIfWhenHasNoStartAndStopDateAndText = () => {
		return (!when.startDate && !when.stopDate && !when.text);
	}

	return (
		<>
			{isEditable &&
				<EditableField field="showBookButton">
					<input type="checkbox" checked={booking != null} />
				</EditableField>
			}
			{booking && (
				<EditableField field="bookButtonText">
					<Button
						displayWidth="full"
						size="smaller"
						className="my-2 font-normal"
						variant="experience"
						onClick={openModal}
					>
						{booking.buttonText}
					</Button>
				</EditableField>
			)}
			{isEditable &&
				<div>
					{booking &&
					<>
						<EditableField field="bookingHeading">
							<p>{booking.heading}</p>
						</EditableField>
						<EditableField field="bookingText">
							<p>{booking.text}</p>
						</EditableField>
						<EditableField field="bookingLink">
							<p>{booking.link ? booking.linkCaption : null}</p>
						</EditableField>
						<EditableField field="bookingLinkText">
							<p>{booking.linkText}</p>
						</EditableField>
					</>
					}
				</div> 
			}
			<div className="px-2 md:px-0">
				<div className="border-t border-black">
					<p className="py-2 pb-4">{when.title}</p>

					{checkIfWhenHasNoStartAndStopDateAndText() &&
						<p className='text-sm pb-3 font-benton'>{when.wholeYearText}</p>
					}

					{(when.text || isEditable) &&
						<EditableField field="when">
							<p className='text-sm pb-3 font-benton'>{when.text}</p>
						</EditableField>
					}

					{(checkIfWhenHasStartDateAndWhenTextIsNull() || isEditable) &&
						<EditableField field="startDate">
							<p className='text-sm pb-3 font-benton'>{when.startDate}</p>
						</EditableField>
					}

					{(checkIfWhenHasStopDateAndWhenTextIsNull() || isEditable) &&
						<EditableField field="stopDate">
							<p className='text-sm pb-3 font-benton'>{when.stopDate}</p>
						</EditableField>
					}
				</div>	
				
				{(checkIfLocationHasLongLatImage() || checkIfLocationHasTextOrName() || isEditable) &&
					<div  className="border-t border-black">
						<p className="py-2 pb-4">{location.title}</p>

						{(location.locationName || isEditable) &&
							<EditableField field="locationName">
								<p className="text-sm font-benton mb-1">{location.locationName}</p> 
							</EditableField>
						}

						{(location.locationImage || isEditable) &&
							<div className="mb-1" style={{ maxWidth: '50px' }}>
								<EditableField field="locationImage">
									{location.locationImage && <Image src={location.locationImage.src} alt={location.locationImage.alt} />}
								</EditableField>
							</div>
						}

						{(location.text || isEditable) && 
							<EditableField field="locationDescription">
								<p className="text-sm mb-1 font-benton">{location.text}</p> 
							</EditableField>
						}

						{(checkIfLocationHasLongLatImage() || isEditable) && 
							
							<p className="text-xs pb-4">
								<EditableField field="locationLatitude">
									<span>{location.locationLatitude}</span> 	
								</EditableField>
								 /
								<EditableField field="locationLongitude">
									<span>{location.locationLongitude}</span> 	
								</EditableField> 
							</p>
						}
					</div>
				}
					
				{(specialEquipment.text || isEditable) && 
					<div  className="border-t border-black">
						<p className="py-2 pb-4">{specialEquipment.title}</p>
						<EditableField field="specialEquipment">
							<Text className="text-sm pb-3 font-benton" as="p">{specialEquipment.text}</Text>	
						</EditableField>	
					</div>
				}

				{(otherInformation.text || isEditable) &&
					<div  className="border-t border-black">
						<p className="py-2 pb-4">{otherInformation.title}</p>
						<EditableField field="otherInformation">
							<Text className="text-sm pb-3 font-benton" as="p">{otherInformation.text}</Text>			
						</EditableField>
					</div>
				}
			</div>

			{booking && (
				<Modal>
					<div className="bg-white relative p-6 rounded flex flex-col">
						<h2 className="text-base font-bold py-5">{booking.heading}</h2>
						<p className="text-sm mb-3">{booking.text}</p>
						<Link
							href={booking.link}
							className="arrow-link-black text-sm hover:underline md:mt-4 mb-3 block"
						>
							{booking.linkText}
						</Link>
					</div>
				</Modal>
			)}
		</>
	);
};

export default ActivityInfo;
