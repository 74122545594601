/**
 * CarouselToggleFullScreen
 */
import Image from 'components/Image/Image';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider, { Settings } from 'react-slick';
import { breakpointsNumber, MinBreakpoint } from 'theme/media-queries';
import { ICarouselSlide, IImage, InfoImage } from 'types/global';
import ImageInfoBlock from 'components/ImageInfoBlock';

type Props = {
	slides: ICarouselSlide[];
	fullscreenOpenLabel?: string;
	fullscreenCloseLabel?: string;
};

const CarouselToggleFullScreen: React.FC<Props> = ({
	slides,
	fullscreenOpenLabel,
	fullscreenCloseLabel,
}) => {
	const isDesktop = useMediaQuery({
		minWidth: breakpointsNumber.desktop,
	});
	const isTablet = useMediaQuery({
		maxWidth: MinBreakpoint.tablet,
	});
	const [isFullscreen, setIsFullscreenMode] = useState(false);
	const [openInfoBlock, setOpenInfoBlock] = useState<string>('');

	const handleToggleFullscreen = () => {
		setIsFullscreenMode(!isFullscreen);
	};

	const settings:Settings = {
		dots: true,
		arrows: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		className: 'slick-fullscreen',
		appendDots: (dots: any) => {
			const activeIndex = dots.findIndex(
				(dot: any) => dot.props.className === 'slick-active'
			);

			return (
				<div>
					<div className="carousel__dots px-8 py-2">
						<div className="carousel__dots__section">
							<ul className="m-0">{dots}</ul>

							{activeIndex > -1 && (
								<div className="carousel__infobox">
									<p>{`${slides[activeIndex].image.title}, ${slides[activeIndex].image.photographer}`}</p>
								</div>
							)}
						</div>
						{isDesktop && (
							<button onClick={handleToggleFullscreen}>
								{isFullscreen ? fullscreenCloseLabel : fullscreenOpenLabel}
								<img
									alt={
										isFullscreen ? fullscreenCloseLabel : fullscreenOpenLabel
									}
									aria-hidden="true"
									className="ml-4"
									src={
										isFullscreen
											? '/assets/images/fullscreen-icon-close.png'
											: '/assets/images/fullscreen-icon.png'
									}
								/>
							</button>
						)}
					</div>
				</div>
			);
		},
		customPaging: (i: number) => (
			<div key={i} className="slick__dots--custom" />
		),
	};

	const handleInfoBlock = (id: string) => {
		setOpenInfoBlock(id);
	};

	return (
		<div {...(isFullscreen && { className: 'slick-dialog' })}>
			<Slider
				{...settings}
				{...(isFullscreen && {
					className: 'slick-fullscreen slick-fullscreen-active relative',
				})}
			>
				{slides.map((slide: ICarouselSlide, index: number) => (
					<div className="relative">
						{!isTablet &&
							slide.image.imageInfos &&
							slide.image.imageInfos.map((item: InfoImage, i:number) => {
								return (
									<ImageInfoBlock
										infoBlock={item}
										id={`${item.modelType}+${i}`}
										key={`${item.modelType}+${i}`}
										openInfoBlock={openInfoBlock}
										handleClick={handleInfoBlock}
									/>
								);
							})}

						<Image
							key={`${slide.image.src}-${index}`}
							src={slide.image.src}
							className={!isFullscreen ? "w-full carousel" : ""}
							alt={slide.image.alt}
							srcSet={slide.image.srcSet}
							srcSetSizes={slide.image.srcSetSizes}
							fetchpriority={index == 0 ? "high" : "low"}
						/>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default CarouselToggleFullScreen;
