/**
 * Pager
 */

import React from 'react';
import PagerBtn from './PagerBtn';
import Icon from 'components/Icon';

interface Props {
	/** The total number of pages */
	nrOfPages: number;

	/** Current page */
	currentPage?: number;

	/** If the jump to first page button should be shown */
	showFirstBtn?: boolean;

	/** If the jump to last page button should be shown */
	showLastBtn?: boolean;

	/** Aria-label for the nav tag */
	ariaLabel?: string;

	/** If a search query, add to links */
	query?: string;

	onPageClick?: any;
}

const getPageNumbers = (currentPage: number, nrOfPages: number) => {
	let numbers = [];

	if (currentPage >= 1) {
		numbers.push(currentPage);
	}

	if (currentPage >= 3) {
		numbers.push(currentPage - 1);
		numbers.push(currentPage - 2);
	}

	if (currentPage + 2 <= nrOfPages) {
		numbers.push(currentPage + 2);
		numbers.push(currentPage + 1);
	}

	numbers.sort((a, b) => a - b);

	return numbers;
};

/** Pager component for search results page. */
const Pager: React.FC<Props> = ({
	nrOfPages,
	currentPage = 1,
	showFirstBtn,
	showLastBtn,
	ariaLabel = 'Paginering',
	query = '',
	onPageClick,
}) => {
	const pageNumbers = getPageNumbers(currentPage, nrOfPages);

	const shouldRenderJumpToFirstPageBtn = currentPage !== 1 && currentPage > 2;
	const shouldRenderPreviousBtn = currentPage !== 1;

	const shouldRenderNextBtn = currentPage < nrOfPages;
	const shouldRenderJumpToLastPageBtn =
		currentPage < nrOfPages - 1 && currentPage !== nrOfPages;

	const itemClasses =
		'py-2 px-4 underline hover:no-underline flex items-center justify-center';
	const activeClasses = ' no-underline pointer-events-none font-bold';

	return (
		<nav className="text-base" aria-label={ariaLabel}>
			<ul className="list-none inline-flex items-center p-0">
				{showFirstBtn && shouldRenderJumpToFirstPageBtn && (
					<PagerBtn
						href={`?pn=1${query}`}
						buttonKey="first"
						label="Första sidan"
						onClick={() => onPageClick(1)}
					>
						<Icon icon="chevron" aria-hidden="true" size={0} />
					</PagerBtn>
				)}
				{shouldRenderPreviousBtn && (
					<PagerBtn
						href={`?pn=${currentPage - 1}${query}`}
						buttonKey="prev"
						label="Föregående sida"
						onClick={() => onPageClick(currentPage - 1)}
					>
						<Icon icon="chevron" aria-hidden="true" size={0} />
					</PagerBtn>
				)}

				{!pageNumbers.includes(1) && (
					<li>
						<a
							href={`?pn=1${query}`}
							className={`${itemClasses}${
								currentPage === 1 ? activeClasses : ''
							}`}
							onClick={(e: any) => {
								e.preventDefault();
								onPageClick(1);
							}}
						>
							1
						</a>
					</li>
				)}
				{pageNumbers.length > 2 && !pageNumbers.includes(2) && (
					<li className={`${itemClasses} no-underline`} key={-1}>
						...
					</li>
				)}

				{pageNumbers.map((number: number) => (
					<li key={number}>
						<a
							className={`${itemClasses}${
								currentPage === number ? activeClasses : ''
							}`}
							href={`?pn=${number}${query}`}
							onClick={(e: any) => {
								e.preventDefault();
								onPageClick(number);
							}}
						>
							{number}
						</a>
					</li>
				))}

				{!pageNumbers.includes(nrOfPages) && (
					<>
						{!pageNumbers.includes(nrOfPages - 1) && (
							<li className={`${itemClasses} no-underline`}>...</li>
						)}

						<li>
							<a
								className={`${itemClasses}${
									nrOfPages === currentPage ? activeClasses : ''
								}`}
								href={`?pn=${nrOfPages}${query}`}
								onClick={(e: any) => {
									e.preventDefault();
									onPageClick(nrOfPages);
								}}
							>
								{nrOfPages}
							</a>
						</li>
					</>
				)}

				{shouldRenderNextBtn && (
					<PagerBtn
						href={`?pn=${currentPage + 1}${query}`}
						buttonKey="next"
						label="Nästa sida"
						onClick={() => onPageClick(currentPage + 1)}
					>
						<Icon icon="chevron" aria-hidden="true" direction="left" size={0} />
					</PagerBtn>
				)}

				{showLastBtn && shouldRenderJumpToLastPageBtn && (
					<PagerBtn
						href={`?pn=${nrOfPages}${query}`}
						buttonKey="last"
						label="Sista sidan"
						onClick={() => onPageClick(nrOfPages)}
					>
						<Icon icon="chevron" aria-hidden="true" direction="left" size={0} />
					</PagerBtn>
				)}
			</ul>
		</nav>
	);
};

export default Pager;
