import { useRef, useEffect } from 'react';

interface UseFirstRender {
	(): boolean;
}

export const useFirstRender: UseFirstRender = () => {
	const isFirstRenderRef = useRef(true);

	useEffect(() => {
		isFirstRenderRef.current = false;
	}, []);

	return isFirstRenderRef.current;
};
