/**
 * Button
 */

import React from 'react';
import { ButtonProps } from '../types';

const Button: React.FC<ButtonProps> = ({
	type = 'button',
	onFieldChange,
	resetForm,
	showError,
	visibleFields,
	children,
	className,
	...propsToTransfer
}) => {
	if (type === 'reset') {
		return (
			<button
				className={className}
				type={type}
				{...propsToTransfer}
				onClick={resetForm}
			>
				{children}
			</button>
		);
	} else {
		return (
			<button className={className} type={type} {...propsToTransfer}>
				{children}
			</button>
		);
	}
};

export default Button;
