import { ReactComponent as chevron } from './icon-chevron.svg';
import { ReactComponent as arrow } from './icon-arrow.svg';
import { ReactComponent as plus } from './icon-plus.svg';
import { ReactComponent as search } from './icon-search.svg';
import { ReactComponent as menu } from './icon-menu.svg';
import { ReactComponent as volUp } from './icon-volumeup.svg';
import { ReactComponent as volDown } from './icon-volumedown.svg';
import { ReactComponent as play } from './icon-play.svg';
import { ReactComponent as spinner } from './icon-spinner.svg';
import { ReactComponent as check } from './icon-check.svg';
import { ReactComponent as exclamation } from './icon-exclamation.svg';
import { ReactComponent as info } from './icon-info.svg';

export interface IconList {
	/** The icon to be shown */
	icon:
		| 'chevron'
		| 'arrow'
		| 'plus'
		| 'search'
		| 'menu'
		| 'volUp'
		| 'volDown'
		| 'play'
		| 'spinner'
		| 'check'
		| 'exclamation'
		| 'info';
}

export default {
	chevron,
	arrow,
	plus,
	search,
	menu,
	volUp,
	volDown,
	play,
	spinner,
	check,
	exclamation,
	info,
};
