const widthClassInGrid = [
	'w-grid-1/12',
	'w-grid-2/12',
	'w-grid-3/12',
	'w-grid-4/12',
	'w-grid-5/12',
	'w-grid-6/12',
	'w-grid-7/12',
	'w-grid-8/12',
	'w-grid-9/12',
	'w-grid-10/12',
	'w-grid-11/12',
	'w-full',
];

export const getWidthClass = (span: number, margin: boolean) => {
    const widthClassInPercent = [
        'w-1/12',
        'w-2/12',
        'w-3/12',
        'w-4/12',
        'w-5/12',
        'w-6/12',
        'w-7/12',
        'w-8/12',
        'w-9/12',
        'w-10/12',
        'w-11/12',
    ];

	if (!span) {
		return span;
	}

	if (span === 12) {
		return 'w-full';
	}
	if (margin) {
		return widthClassInGrid[span - 1];
	} else {
		return widthClassInPercent[span - 1];
	}
};

export const getWidthClassTablet = (span: number, margin: boolean) => {
    const widthClassInPercent = [
        'md:w-1/12',
        'md:w-2/12',
        'md:w-3/12',
        'md:w-4/12',
        'md:w-5/12',
        'md:w-6/12',
        'md:w-7/12',
        'md:w-8/12',
        'md:w-9/12',
        'md:w-10/12',
        'md:w-11/12',
    ];

	if (!span) {
		return span;
	}

	if (span === 12) {
		return 'w-full';
	}
	if (margin) {
		return widthClassInGrid[span - 1];
	} else {
		return widthClassInPercent[span - 1];
	}
};
export const getWidthClassDesktop = (span: number, margin: boolean) => {
    const widthClassInPercent = [
        'lg:w-1/12',
        'lg:w-2/12',
        'lg:w-3/12',
        'lg:w-4/12',
        'lg:w-5/12',
        'lg:w-6/12',
        'lg:w-7/12',
        'lg:w-8/12',
        'lg:w-9/12',
        'lg:w-10/12',
        'lg:w-11/12',
    ];

	if (!span) {
		return span;
	}

	if (span === 12) {
		return 'w-full';
	}
	if (margin) {
		return widthClassInGrid[span - 1];
	} else {
		return widthClassInPercent[span - 1];
	}
};