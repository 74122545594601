import React from 'react';
import { useSelector } from 'react-redux';
import { selectSkipToMain } from 'store/modules/model';

import Component from './SkipToMain';

const SkipToMain = () => {
	const skipToMain: any = useSelector(selectSkipToMain);

	return <Component {...skipToMain} />;
};

export default SkipToMain;
