import { ContentTypes } from 'types/pages';
import { IImage, Link } from 'types/global';

declare global {
	interface Window {
		__model: any;
		epi: any;
	}
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace NodeJS {
		interface Global {
			window: any;
			document: any;
			Styled: any;
			React: any;
			ReactDOM: any;
			ReactDOMServer: any;
		}
	}
}

export interface EpiEvent {
	isEditable: boolean;
	inEditMode: boolean;
	inPreviewMode: boolean;
	ready: boolean;
}

export interface Model {
	head?: ModelHead;
	metadata?: ModelMetadata;
	header?: ModelHeader;
	responsible?: ModelResponsible;
	footerLinks?: ModelFooter;
	breadcrumbs?: ModelBreadcrumbs;
	share?: any;
	search?: ModelSearch;
	content?: ContentTypes;
	translations?: TranslationModel;
	readSpeaker?: ReadSpeakerModel;
	languageSelector?: LanguageSelectorModel;
	navigation?: NavigationModel;
	home?: HomeModel;
	topLinks?: TopLinksModel;
	menu?: MenuModel;
	skipToMain?: any;
	skipToTop?: { text: string };
}

export interface ContentLinkResponse {
	status: number;
	result: ContentTypes;
}

export interface ContentSavedEvent {
	contentLink: string;
	properties: ContentSavedProperty[];
}

export interface ContentSavedProperty {
	name: string;
	successful: boolean;
	validationErrors: any;
	value: string;
}

export interface MenuModel {
	items: MenuItemModel[];
}

export interface MenuItemModel {
	url: string;
	text: string;
	selected: boolean;
}

export interface TopLinksModel {
	topLinks: TopLink[];
}

export interface TopLink {
	href: string;
	text: string;
	title: string;
	target?: string;
}

export interface NavigationModel {
	accessibilityDescription: string;
	openNavigationPaneLabel: string;
	closeNavigationPaneLabel: string;
	openNavigationItemLabel: string;
	closeNavigationItemLabel: string;
	navigationButtonLabel: string;
	screenReaderText: string;
	items: NavigationItem[];
}

interface NavigationItem {
	url: string;
	text: string;
	title: string;
	isOpen: boolean;
	isActive: boolean;
	items: NavigationItem[];
}

interface HomeModel {
	url: string;
	alt: string;
	logo: string;
}

interface ReadSpeakerModel {
	url: string;
	text: string;
}

interface ModelMetadata {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	googleSiteVerificationTag?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
}

interface ModelHead {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	openGraphTitle?: string | null;
	openGraphImageUrl?: string | null;
	openGraphDescription?: string | null;
	twitterTitle?: string | null;
	twitterDescription?: string | null;
	twitterImageUrl?: string | null;
}

interface ModelHeader {
	homeLogo: {
		src: string;
		alt?: string;
	};
	homeLink: Link;
	topLinkItems?: Link[];
	menuItems: Link[];
}

interface LanguageSelectorModel {
	currentCulture: string;
	languages: LanguageSelectorItem[];
}

export interface LanguageSelectorItem {
	url: string;
	culture: string;
	text: string;
}

export interface ModelFooter {
	footerLinks: FooterLink[];
}

interface FooterLink {
	url: string;
	text: string;
	title: string;
}

export interface ModelResponsible {
	name: string;
	logo: IImage;
	url: string;
}

interface ModelBreadcrumbs {
	label: string;
	breadcrumbs: Link[];
}

interface ModelSearch {
	language?: string;
	searchPageUrl?: string;
	apiUrl?: string;
	autocompleteApiUrl?: string;
	numberOfHitsPerPage?: number;
	inputId: string;
	placeholderText?: string;
	searchLabel: string;
	submitText: string;
	queryName: string;
}

export interface ModelReact {
	containerId: string;
	clientOnly: boolean;
	siteBaseUrl: string;
	apiUrl: string;
	apiEditUrl: string;
	renderingState: RenderingStates;
}

export enum RenderingStates {
	ServerSide = 1,
	Hydrate,
	ClientSide,
}

export interface TranslationModel {
	target: string;
	skipToMainText: string;
	menuLabel: string;
	menuText: string;
	menuCloseText: string;
	screenReaderShowAllText: string;
}
