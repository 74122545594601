/**
 * ActivitiesPage
 */

import React from 'react';
import { ActivitiesPageModel } from 'types/pages';
import Share from 'components/Share';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Grid } from 'components/Grid';
import ActivityList from 'components/ActivityList';
import { useMediaQuery } from 'react-responsive';

/** Page for listing activities for all parks and specific park */
const ActivitiesPage: React.FC<ActivitiesPageModel> = ({
	groups = [],
	heading,
	parkName = '',
	showMoreText,
	_properties,
}) => {
	const isBigTablet = useMediaQuery({ query: '(max-width: 960px)' });
	return (
		<>
			<Breadcrumbs />
			<div className="w-full bg-grey-lightest pb-8">
				{isBigTablet && (
					<h1 className="border-b border-stone-light mb-4 pt-6 pb-2 mx-3 text-xl">
						{parkName}
					</h1>
				)}
				<Grid maxWidth padding={false} margin={false}>
					<div className="border-b-2 border-white w-full mx-3">
						<h1 className="font-alt font-medium text-h1 mt-6 sm:mb-0">
							{heading}
						</h1>
					</div>
					{groups.length && (
						<ActivityList
							{..._properties}
							showMoreText={showMoreText}
							groups={groups}
						/>
					)}
				</Grid>
			</div>

			<Share />
		</>
	);
};

export default ActivitiesPage;
