import { useEffect } from 'react';

interface UseScript {
	(scriptData: { url: string; id?: string; type?: string }): void;
}

export const useScript: UseScript = ({ url, id, type = 'text/javascript' }) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = url;
		script.async = true;
		if (id) script.id = id;
		if (type) script.type = type;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);
};
