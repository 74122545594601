/**
 * SearchPage
 */

import AutoComplete from 'components/AutoComplete';
import { Breadcrumbs } from 'components/Breadcrumbs';
import Loader from 'components/Loader';
import Pager from 'components/Pager';
import ReadSpeaker from 'components/ReadSpeaker';
import { Result, SearchResults } from 'components/SearchResults';
import Share from 'components/Share';
import { useUrlQuery } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectReadSpeaker, selectSearch } from 'store/modules/model';
import { SearchPageModel } from 'types/pages';

/** Search page. */
const SearchPage: React.FC<SearchPageModel> = ({
	heading,
	searchResult,
	translations,
	_properties = {},
}) => {
	const search = useSelector(selectSearch);
	const [value, setValue] = useState(searchResult.query);
	const [allResults, setResults] = useState(searchResult.results);
	const [currentPage, setCurrentPage] = useState(searchResult.page);
	const [pageCount, setPageCount] = useState(searchResult.pageCount);

	const [updateQuery, response, loading] = useUrlQuery(
		{
			query: searchResult.query,
			pn: searchResult.page,
		},
		search?.apiUrl
	);

	const readSpeaker = useSelector(selectReadSpeaker);

	useEffect(() => {
		if (response?.results) {
			setResults(response.results);
			setCurrentPage(response.page);
			setPageCount(response.pageCount);
		}
	}, [response]);

	const searchResultsPhrase = translations.searchResultsPhrase?.split('{0}');

	return (
		<>
			<Breadcrumbs />
			<div className="bg-grey-lighter pt-8 pb-12">
				<div className="max-w-search mx-auto mt-0 px-5">
					<div className="flex justify-between border-solid border-b-2 border-yellow mb-4 rssnp-readarea">
						<h1 className="text-h3 md:text-h1 " {..._properties?.heading}>
							{heading}
						</h1>
						{readSpeaker && (
							<ReadSpeaker
								url={readSpeaker?.url}
								text={readSpeaker?.text}
								className={'my-0 w-20'}
							/>
						)}
					</div>

					{search && (
						<AutoComplete
							id={search.inputId}
							action={search?.searchPageUrl}
							autocompleteApiUrl={search?.autocompleteApiUrl}
							placeholder={translations.searchScreenReaderLabel}
							label={translations.searchScreenReaderLabel}
							submitText={search.searchLabel}
							name={search.queryName}
							onSubmit={(val: string) => {
								setValue(val);
								updateQuery({ query: val, pn: 1, language: search?.language });
							}}
							value={value}
							onChange={(value: string) => {
								setValue(value);
							}}
							largeSearch
						/>
					)}

					<div className="bg-white p-5 md:p-8">
						{loading && (
							<div className="flex justify-center">
								<Loader variant="mini" />
							</div>
						)}

						{!loading && searchResultsPhrase && (
							<p className="text-base mb-4">
								<span className="font-bold">
									{response?.numberOfHits > 0 || searchResult.numberOfHits > 0
										? response?.numberOfHits || searchResult.numberOfHits
										: translations.emptySearchListLabel}{' '}
								</span>
								{searchResultsPhrase[1]}
								<span className="font-bold">
									'{response?.query || searchResult.query}'{' '}
								</span>
								{searchResultsPhrase[2]}
								<span className="font-bold">
									{' '}
									{translations.languageLabel}{' '}
								</span>
								{searchResultsPhrase[3]}
								<span className="font-bold">{translations.webpageLabel}</span>
							</p>
						)}

						{!loading && (
							<SearchResults
								startNumber={
									currentPage === 1
										? 1
										: searchResult.size * (currentPage - 1) + 1
								}
							>
								{allResults &&
									allResults.map((item, i) => (
										<Result
											heading={item.heading}
											excerpt={item.excerpt}
											url={item.url}
											id={item.documentId}
											key={i}
										/>
									))}
							</SearchResults>
						)}

						{allResults && allResults.length > 0 && (
							<div className="mt-4 ml-4 rs_skip">
								<Pager
									nrOfPages={pageCount}
									currentPage={currentPage}
									query={`&query=${response?.query || searchResult.query}`}
									onPageClick={(pn: number) => {
										updateQuery({
											query: value,
											pn: pn,
											language: search?.language,
										});
									}}
									ariaLabel="Paginering"
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<Share />
		</>
	);
};

export default SearchPage;
