/**
 * Icon
 */

import React from 'react';
import icons, { IconList } from 'theme/icons';
import clsx from 'clsx';

type IconSizes = 0 | 1 | 2 | 3 | 4 | 5;
type IconDirections = 'left' | 'up' | 'right' | 'down';

interface Props extends IconList {
	/** Set the size of the icon. <br/>1 = 24px<br/> 2 = 32px<br/> 3 = 40px <br/> 4 = 48px*/
	size?: IconSizes;

	/** Set the direction of the icon. */
	direction?: IconDirections;

	/** Animate the icon. */
	animate?: 'spin' | 'none';

	/** Pass optional classnames for the html */
	className?: string;

	[htmlAttributes: string]: any;
}

const getSize = (size: IconSizes) => {
	switch (size) {
		case 0:
			return 'w-3 h-3';
		case 1:
			return 'w-4 h-4';
		case 2:
			return 'w-6 h-6';
		case 3:
			return 'w-8 h-8';
		case 4:
			return 'w-10 h-10';
		case 5:
			return 'w-12 h-12';
		default:
			break;
	}
};

const getRotation = (direction: IconDirections) => {
	switch (direction) {
		case 'up':
			return '-rotate-90';
		case 'right':
			return 'rotate-0';
		case 'down':
			return 'rotate-90';
		case 'left':
			return 'rotate-180';
	}
};

/** This is the icon component that handles all the icons in the project. <br /> The user will be able to set the <strong>color</strong>, <strong>size</strong>, <strong>direction</strong> and <strong>animation</strong> for all the icons. */
const Icon: React.FC<Props> = ({
	icon,
	size = 1,
	direction = 'right',
	animate = undefined,
	className,
	expanded,
	...htmlAttributes
}) => {
	const IconSvg = icons[icon];

	return (
		<IconSvg
			className={clsx(
				className,
				'inline-block',
				'align-middle',
				'transition-all',
				'duration-300',
				!animate && 'transform',
				'fill-current',
				getSize(size),
				getRotation(direction),
				animate === 'spin' && 'animate-spin'
			)}
			{...htmlAttributes}
		/>
	);
};

export default Icon;
