/**
 * TextArea
 */

import React, { useState, useRef, useEffect } from 'react';
import { TextAreaProps } from '../types';
import { useFieldValidation } from '../hooks/useFieldValidation';
import { useFormValue } from '../context/Form.context';
import clsx from 'clsx';

const TextArea: React.FC<TextAreaProps> = (props) => {
	const {
		className,
		id,
		description,
		name,
		placeholder,
		defaultValue,
		required,
		disabled,
		autoSubmit,
		onFieldChange,
		showError,
		visibleFields,
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [value, setValue] = useState(defaultValue || '');
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		const validatedField = validateField(value);
		const fieldIsValid = !validatedField.message;

		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
		}

		if (touched && !fieldIsValid) {
			showFieldError(validatedField);
		} else if (
			(showError &&
				!fieldIsValid &&
				visibleFields &&
				visibleFields.includes(id)) ||
			(showError && !fieldIsValid && !visibleFields)
		) {
			setTouched(true);
			showFieldError(validatedField);
		} else {
			hideFieldError();
		}
	}, [touched, showError, value]);

	const onChange = (e: React.FormEvent<HTMLElement>) => {
		const target = e.target as HTMLFormElement;

		setValue(target.value);
		onFieldChange({
			name,
			value: target.value,
			type: 'TextArea',
			autoSubmit,
		});
	};

	return (
		<textarea
			className={clsx(
				'p-1',
				'border-2',
				'border-black',
				'border-solid',
				'min-h-184',
				className
			)}
			id={id}
			title={description}
			aria-invalid={!valid}
			name={name}
			required={required}
			disabled={disabled}
			placeholder={placeholder || ''}
			value={value}
			onChange={onChange}
			onBlur={() => setTouched(true)}
			ref={fieldRef}
			aria-describedby={`form${id}__desc`}
		/>
	);
};

export default TextArea;
