/**
 * Link
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { selectEpi } from 'store/modules/epi';

interface Props {
	/** The url the link should navigate to */
	to?: string;

	/** The url the link should navigate to */
	href?: string;

	/** Pass optional classnames for the html */
	className?: string;

	[htmlAttributes: string]: any;

	external?: boolean;
}

const Link: React.FC<Props> = ({
	to,
	href,
	children,
	className = '',
	external = false,
	...htmlAttributes
}) => {
	const url = to || href || '#';
	const { inEditMode }  = useSelector(selectEpi);

	if ((url && url.charAt(0) !== '/') || external || inEditMode) {
		return (
			<a href={url} className={className} {...htmlAttributes}>
				{children}
			</a>
		);
	} else {
		return (
			<RouterLink to={url} className={className} {...htmlAttributes}>
				{children}
			</RouterLink>
		);
	}
};

export default Link;
