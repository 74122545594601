import Icon from 'components/Icon/Icon';
import React, {
	PropsWithChildren,
	isValidElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';

interface ModalProps {
	noModalPadding?: boolean;
	noCloseButton?: boolean;
}

/* Little hook that is used to wrap content in a modal. */
export const useModal = () => {
	const location = useLocation();
	const isEnglish: boolean = location.pathname.startsWith('/en/');

	const dialogRef = useRef<HTMLDialogElement>(null);
	const [open, setOpen] = useState(false);

	// Open modal
	const openModal = useCallback(() => {
		document.documentElement.classList.add('overflow-hidden');
		setOpen(true);
	}, []);

	// Close modal
	const closeModal = useCallback(() => {
		document.documentElement.classList.remove('overflow-hidden');
		setOpen(false);
	}, []);

	useEffect(() => {
		const clickEscape = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				// Esc button
				closeModal();
			}
		};

		document.addEventListener('keydown', clickEscape);
		return () => document.removeEventListener('keydown', clickEscape);
	}, []);

	useEffect(() => {
		const handleFullscreenChange = () => {
			dialogRef.current?.focus(); // make sure the focus is on dialog, otherwise the focus will be on iframe document and keydown event wont be triggered
		};

		document.addEventListener('fullscreenchange', handleFullscreenChange);
		return () =>
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
	}, []);

	useEffect(() => {
		const { current: el } = dialogRef;
		if (el) {
			if (open) {
				el.showModal();
				el.focus();
			} else el.close();
		}
		return () => {
			if (open) closeModal();
		};
	}, [open, closeModal, dialogRef.current?.open]);

	// Handle click outside of modal
	const onClick = useCallback(
		({ target }) => {
			const { current: el } = dialogRef;
			if (target === el) closeModal();
		},
		[closeModal]
	);

	const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
		children,
		noCloseButton = false,
		noModalPadding = false,
	}) => {
		return (
			<dialog
				role="dialog"
				aria-modal="true"
				aria-hidden={open ? 'false' : 'true'}
				onClick={onClick}
				className="p-0 w-5/6 max-w-2xl overscroll-contain overflow-auto"
				ref={dialogRef}
			>
				<div className={`${noModalPadding ? '' : 'p-6'}`}>
					{!noCloseButton && (
						<button
							aria-label={isEnglish ? 'Close Pop-up' : 'Stäng Pop-up'}
							onClick={closeModal}
							className="absolute top-1 z-10 right-1"
						>
							<Icon
								icon="plus"
								className="rotate-45 text-grey-light "
								aria-hidden="true"
							/>
						</button>
					)}
					{React.Children.map(children, (child) => {
						if (isValidElement(child))
							return React.cloneElement(child, child.props);
					})}
				</div>
			</dialog>
		);
	};

	return {
		Modal,
		openModal,
		closeModal,
	};
};
