import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { selectEpi } from 'store/modules/epi';

type Props = {
    className?: string;
    inline?: boolean;
    field : string
}

const EditableField: React.FC<PropsWithChildren<Props>> = ({
    className,
    inline,
    field,
    children
}) => {
    const { isEditable } = useSelector(selectEpi);

    const cssClass = `${className ?? '' } opti-edit-container`
    if (!isEditable)
        return <>{children}</>

    return inline ? <span className={`${ cssClass } opti-edit-container-inline`} data-epi-edit={field}>{children}</span> :
        <div className={`${ cssClass } opti-edit-container-block`} data-epi-edit={field}>{children}</div>
}

export default EditableField