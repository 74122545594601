import React from 'react';
import { useSelector } from 'react-redux';
import { selectHome } from 'store/modules/model';

import Component from './Home';

const Home = () => {
	const home: any = useSelector(selectHome);

	return <>{home && home.logo && <Component {...home} />}</>;
};

export default Home;
