import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSearch } from 'store/modules/model';

import Icon from 'components/Icon';
import Home from 'components/Home';
import Languages from 'components/Languages';
import TopLinks from 'components/TopLinks';
import MobileMenu from 'components/MobileMenu';
import AutoComplete from 'components/AutoComplete';

interface Props {
	menuButtonLabel: string;
}

/** The page header component. */
export const MobileHeader: React.FC<Props> = ({ menuButtonLabel }) => {
	const [searchOpen, setSearchOpen] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);

	const search = useSelector(selectSearch);
	const handleCloseMenu = () => setMenuOpen(!menuOpen);

	return (
		<header className="bg-dim-grey">
			<div className="pl-3 pr-6 border-t border-black-25">
				<div className="flex items-center justify-between md:max-w-header md:mx-auto">
					<button
						type="button"
						onClick={() => {
							setSearchOpen(false);
							setMenuOpen((open) => !open);
						}}
						aria-expanded={menuOpen}
					>
						<Icon
							icon="menu"
							aria-hidden="true"
							size={2}
							className="text-white"
						/>
						<span className="ml-2 text-white font-bold">{menuButtonLabel}</span>
					</button>

					<Home />

					<button
						type="button"
						onClick={() => {
							setMenuOpen(false);
							setSearchOpen((open) => !open);
						}}
						aria-expanded={searchOpen}
					>
						<Icon
							icon="search"
							aria-hidden="true"
							size={2}
							className="text-white"
							direction="down"
						/>
						<span className="ml-2 text-white font-bold">
							{search?.searchLabel}
						</span>
					</button>
				</div>
			</div>

			<div
				className="border-t-2 border-gray-600"
				hidden={!menuOpen}
				aria-hidden={!menuOpen}
			>
				<TopLinks forMobile={true} />

				<div className="px-6 py-2.5">
					<MobileMenu
						onClose={handleCloseMenu}
						onLinkClick={() => {
							setMenuOpen(false);
						}}
					/>
				</div>

				<Languages
					forMobile={true}
					onLanguageClick={() => {
						setMenuOpen(false);
					}}
				/>
			</div>

			{search && (
				<div
					className="bg-dim-grey border-t-2 border-gray-600 py-3 px-2.5"
					hidden={!searchOpen}
					aria-hidden={!searchOpen}
				>
					<AutoComplete
						id={search.inputId}
						action={search?.searchPageUrl}
						autocompleteApiUrl={search?.autocompleteApiUrl}
						placeholder={search?.placeholderText}
						label={search.searchLabel}
						submitText={search.searchLabel}
						name={search.queryName}
					/>
				</div>
			)}
		</header>
	);
};
