import React from 'react';
import { useSelector } from 'react-redux';
import { selectNavigation } from 'store/modules/model';

import Component from './Navigation';

const Navigation = () => {
	const navigation: any = useSelector(selectNavigation);

	return (
		<>
			<Component {...navigation} />
		</>
	);
};

export default Navigation;
