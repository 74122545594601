/**
 * StandardBlock
 */

import Icon from 'components/Icon';
import Image from 'components/Image';
import Link from 'components/Link';
import React from 'react';
import type { IImage } from 'types/global';

interface Props {
	/** Block heading */
	heading?: string;

	/** Block image */
	image: IImage;

	/** Block url */
	url: string;

	/** Block time */
	when?: string;

	isPark?: boolean;
}

/** A standard implementation of an article, event etc. */
const StandardBlock: React.FC<Props> = ({
	heading,
	image,
	url,
	when,
	isPark = false,
}) => {
	return (
		<div className="block mx-3 my-1 bg-white break-words cursor-pointer puff-shadow flex-grow">
			<Link to={url} className="block focus:puff-shadow-focus">
				{image && (
					<div>
						<Image 
						srcSetSizes={image.srcSetSizes} 
						srcSet={image.srcSet} 
						src={image.src}
						decoding="async"
						loading="lazy" 
						alt={image.alt} />
					</div>
				)}

				<div className="flex-col p-2">
					<div className="flex items-baseline">
						<Icon
							size={0}
							className="mr-1"
							direction="left"
							icon="chevron"
							aria-hidden={true}
						/>
						{isPark ? (
							<h3 className="my-0 font-bold text-base">{heading}</h3>
						) : (
							<h3 className="my-0 min-h-65 font-normal text-base">{heading}</h3>
						)}
					</div>

					{when && (isPark ? <p>{when}</p> : <p className="text-sm">{when}</p>)}
				</div>
			</Link>
		</div>
	);
};

export default StandardBlock;
