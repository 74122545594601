/**
 * Navigation
 */

import React from 'react';
import clsx from 'clsx';
import Link from '../Link';

interface Props {
	accessibilityDescription: string;
	openNavigationPaneLabel: string;
	closeNavigationPaneLabel: string;
	openNavigationItemLabel: string;
	closeNavigationItemLabel: string;
	navigationButtonLabel: string;
	screenReaderText: string;
	items: NavigationItem[];
}

interface NavigationItem {
	url: string;
	text: string;
	title: string;
	isOpen: boolean;
	isActive: boolean;
	isNavigationRoot: boolean;
	items: NavigationItem[];
}

const Navigation: React.FC<Props> = ({ accessibilityDescription, items }) => {
	return (
		<nav className="flex-grow" aria-label={accessibilityDescription}>
			<ul className="flex text-sm lg:text-base text-white">
				{items
					.filter((i) => !i.isNavigationRoot)
					.map((item: any, i: number) => (
						<li key={i}>
							<Link
								href={item.url}
								className={clsx(
									'text-sm hover:text-yellow mr-4',
									item.isActive && 'text-yellow'
								)}
							>
								{item.text}
							</Link>
						</li>
					))}
			</ul>
		</nav>
	);
};

export default Navigation;
