/**
 * Tooltip
 */

import React, { useState, useRef } from 'react';
import clsx from 'clsx';

interface Props {
	/** This is the text that goes in the tooltip. */
	text?: string;

	/** Defines CSS for container element */
	containerStyle?: string;

	/** Defines CSS for toolTip element */
	toolTipStyle?: string;
}

interface Position {
	x: number;
	y: number;
}

/** Main description for this component. */
const Tooltip: React.FC<Props> = ({
	text,
	containerStyle,
	toolTipStyle,
	children,
}) => {
	const [pos, setPos] = useState<Position>({ x: 0, y: 0 });
	const [show, setShow] = useState<boolean>(false);

	let timer: NodeJS.Timeout;
	let timerRef = useRef<NodeJS.Timeout | any>(null);
	let toolTipRef = useRef<boolean | null>(null);
	let toolTipEl = useRef<HTMLDivElement>(null);

	const handleMouse = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		switch (e.type) {
			case 'mouseenter':
				setShow(true);
				toolTipRef.current = true;
				return;
			case 'mouseover':
				if (!show) {
					timer = setTimeout(() => {
						setShow(true);
						toolTipRef.current = true;
						if (toolTipEl.current?.clientHeight) {
							let windowBottom = window.innerHeight + window.pageYOffset;
							let breakpoint = pos.y + toolTipEl.current?.clientHeight;
							if (breakpoint > windowBottom) {
								const position = {
									x: pos.x,
									y: pos.y - toolTipEl.current?.clientHeight,
								};
								setPos(position);
							}
						}
					}, 500);
					timerRef.current = timer;
				}
				return;
			case 'mouseleave':
				if (toolTipRef.current) {
					setShow(false);
					return;
				}
				setShow(false);
				clearTimeout(timerRef.current);
				return;
			case 'mouseout':
				setShow(false);
				toolTipRef.current = false;
				return;
			default:
				return;
		}
	};

	const handleMove = (e: any) => {
		e.stopPropagation();
		if (!show) {
			let x = e.clientX;
			let y = e.clientY + window.pageYOffset;

			const currentPosition = { x, y };
			setPos(currentPosition);
		}
	};

	return (
		<>
			<div
				className={clsx(containerStyle)}
				onMouseOver={handleMouse}
				onMouseLeave={handleMouse}
				onMouseMove={handleMove}
			>
				{children}
			</div>
			{show && (
				<div
					ref={toolTipEl}
					onMouseOut={handleMouse}
					onMouseEnter={handleMouse}
					className={clsx(toolTipStyle, 'overflow-visible', 'max-h-')}
					style={{
						position: 'absolute',
						top: pos.y + 15 + 'px',
						left: pos.x / 1.2 + 'px',
					}}
				>
					{text}
				</div>
			)}
		</>
	);
};

export default Tooltip;
