/**
 * ActivitesPuff
 */

import React from 'react';

import Text from 'components/Text';
import Button from 'components/Button';
import clsx from 'clsx';

interface Props {
	/** The list heading */
	heading: string;

	/** The list preamble */
	text?: any;

	/** Button url */
	url?: string;

	/** Button text */
	buttonText: string;

	/** Epi attributes */
	_properties: any;

	/** background color */
	colorTheme: string;

	modelType?: string;
}

/** A list of activities. */
const ActivitesPuff: React.FC<Props> = ({
	heading,
	text,
	url,
	children,
	buttonText,
	_properties,
	colorTheme,
	modelType,
}) => {
	return (
		<div className={clsx(
			"w-full py-14",
             colorTheme === "grey-lighter" ? "bg-grey-lighter" : "bg-white"
		)}>
			<div className="max-w-5xl lg:max-w-site mx-auto flex flex-col items-center px-4">
				<div className="text-center">
					<Text as="h2" className="font-bold" {..._properties?.heading}>
						{heading}
					</Text>
				</div>
				{text && (
					<p
						className="max-w-2xl text-center text-lg leading-6"
						{..._properties?.text}
					>
						{text}
					</p>
				)}

				<div
					className={` ${
						modelType === 'ArticlesPuff'
							? 'flex w-full mt-4 flex-wrap'
							: 'flex w-full mt-4 flex-wrap md:flex-nowrap'
					} `}
				>
					{children}
				</div>

				{url && (
					<Button
						className="mt-6"
						displayWidth="dynamic"
						size="medium"
						as="a"
						href={url}
						variant="primary"
					>
						{buttonText}
					</Button>
				)}
			</div>
		</div>
	);
};

export default ActivitesPuff;
