/**
 * ParkPage
 */

import { Breadcrumbs } from 'components/Breadcrumbs';
import EpiContentArea from 'components/EpiContentArea';
import Carousel from 'components/Carousel';
import Link from 'components/Link';
import ReadSpeaker from 'components/ReadSpeaker';
import Share from 'components/Share';
import Text from 'components/Text';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectReadSpeaker } from 'store/modules/model';
import { ParkPageModel } from 'types/pages';
import EditableField from 'components/EditableField';
import { useScript } from 'hooks';

/** Main description for this component. */
const ParkPage: React.FC<ParkPageModel> = ({
	carousel,
	contentArea,
	contentAreaAboveMap,
	puffHeading,
	preamble,
	secondHeading,
	linkText,
	linkUrl,
	parkMapHtml,
	parkMapScript,
	_properties,
}) => {
	const readSpeaker = useSelector(selectReadSpeaker);

	useScript({
		url: parkMapScript ? parkMapScript : "",
		id: "naturkartanMap"
	});

	return (
		<>
			<Breadcrumbs />
			{carousel && <Carousel {...carousel} />}
			<div className="rssnp-readarea w-full lg:w-full">
				<div className="w-full py-4 relative mt-4">
					{readSpeaker && (
						<ReadSpeaker
							url={readSpeaker?.url}
							text={readSpeaker?.text}
							className="md:absolute md:right-4 justify-center"
						/>
					)}
					<div className="max-w-site mx-auto flex flex-col items-center px-4">
						<div className="text-center">
								<Text
									as="h1"
									margin={false}
									className="park-page-h1 text-2xl"
									{..._properties?.heading}
								>
									{puffHeading}
								</Text>
							<EditableField field="subheading">
								<Text as="h2" className="park-page-h2">
									{secondHeading}
								</Text>
							</EditableField>
						</div>
						<div className="text-center text-base md:text-lg max-w-3xl leading-7">
							<EditableField field="preamble">
								<Text className='leading-normal text-lg md:max-w-2xl' as="p">{preamble}</Text>
							</EditableField>
							{linkUrl && (
								<Link
									href={linkUrl}
									className="arrow-link-black text-lg hover:underline md:mt-4 mb-3 block"
								>
									{linkText}
								</Link>
							)}
						</div>
					</div>
				</div>
			</div>

			<EpiContentArea
				{..._properties?.contentAreaAboveMap}
				content={contentAreaAboveMap}
			/>

			{parkMapHtml && (
				<div className="mr-4">
					<div id="map-container" className="h-112.5 md:h-143.5 xl:h-223.5 relative" dangerouslySetInnerHTML={{
						__html: parkMapHtml,
					}} />
				</div>
			)}

			<EpiContentArea {..._properties?.contentArea} content={contentArea} />

			<Share />
		</>
	);
};

export default ParkPage;
