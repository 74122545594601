import { math } from 'polished';

export interface Breakpoints {
	[device: string]: string;
}

export interface BreakpointsNumber {
	[device: string]: number;
}

export type BreakpointDevices = {
	tablet: number;
	desktop: number;
};

export const MinBreakpoint: BreakpointDevices = {
	tablet: 768,
	desktop: 1024,
};

// Must be set in numbers
export const breakpointsNumber: BreakpointsNumber = {
	desktopL: 1024,
	desktop: 840,
	tablet: 480,
	phone: 0,
};

// Must be set in pixels
export const breakpoints: Breakpoints = {
	desktop: `${breakpointsNumber.desktop}px`,
	tablet: `${breakpointsNumber.tablet}px`,
	phone: `${breakpointsNumber.phone}px`,
};

const mediaQueryValues = {
	desktop: `@media screen and (min-width: ${breakpoints.desktop})`,
	tabletOrLarger: `@media screen and (min-width: ${breakpoints.tablet})`,
	phoneOrTablet: `@media screen and (min-width: ${
		breakpoints.phone
	}) and (max-width: ${math(`${breakpoints.desktop} - 1px`)})`,
	tablet: `@media screen and (min-width: ${
		breakpoints.tablet
	}) and (max-width: ${math(`${breakpoints.desktop} - 1px`)})`,
	phone: `@media screen and (min-width: ${
		breakpoints.phone
	}) and (max-width: ${math(`${breakpoints.tablet} - 1px`)})`,
} as const;
type MediaQueries = typeof mediaQueryValues;
export const mediaQueries: MediaQueries = mediaQueryValues;
