/**
 * StandardBlock
 */

import Icon from 'components/Icon';
import Image from 'components/Image';
import Link from 'components/Link';
import React from 'react';
import { IImage } from 'types/global';

interface Props {
	/** Block heading */
	heading?: string;

	/** Block image */
	image: IImage;

	/** Block url */
	url: string;

	/** Block time */
	when?: string;

	/** Block image alt text */
	alt?: string;

	/** Puff title level */
	TitleLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

	isPark?: boolean;
}

/** A standard implementation of an article, event etc. */
const ActivityStandardBlock: React.FC<Props> = ({
	heading,
	image,
	url,
	TitleLevel = "h3",
	when,
	alt = '',
	isPark = false,
}) => {
	return (
		<div className="activity-card block mx-2 my-1 bg-white break-words cursor-pointer puff-shadow">
			<Link to={url} className="focus:puff-shadow-focus block h-full">
				{image && (
					<div className="activity-card--img-wrap">
						<Image 
						src={image.src}
						srcSet={image.srcSet}
						srcSetSizes={image.srcSetSizes}
						alt={image.alt}
						/>
					</div>
				)}

				<div className="flex-col p-2">
					<div className="flex items-baseline">
						<Icon
							size={0}
							className="mr-1"
							direction="left"
							icon="chevron"
							aria-hidden={true}
						/>
						{isPark ? (
							<TitleLevel className="my-0 font-bold text-lg">{heading}</TitleLevel>
						) : (
							<TitleLevel className="my-0 min-h-65 font-normal text-base">{heading}</TitleLevel>
						)}
					</div>

					{when && (isPark ? <p>{when}</p> : <p className="text-sm">{when}</p>)}
				</div>
			</Link>
		</div>
	);
};

export default ActivityStandardBlock;
