/**
 * ParkInformation
 */

import React from 'react';
import { ParkInformationModel } from 'types/blocks';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Link from 'components/Link';

/** Park information component rendered in topcontentarea on parkpage. */
const ParkInformation: React.FC<ParkInformationModel> = ({
	topHeading,
	heading,
	preamble,
	url,
	linkText,
	_properties,
}) => {
	return (
		<Link href={url}>
			<div className="text-center max-w-2xl py-12 mx-auto bg-white">
				<div className="text-center max-w-2xl mx-auto">
					<div className="px-2 md:px-0 rssnp-readarea">
						<Text as="h1" {..._properties?.topHeading} className="mb-12">
							{topHeading}
						</Text>

						{heading && (
							<Text
								as="h2"
								{..._properties?.heading}
								className="text-h3 leading-7 mb-8"
							>
								{heading}
							</Text>
						)}

						<Text as="p" className="mb-0">
							{preamble}
						</Text>

						{linkText && (
							<Text as="p" className="mb-0">
								<Icon direction={'left'} icon={'chevron'} aria-hidden="true" />{' '}
								{linkText}
							</Text>
						)}
					</div>
				</div>
			</div>
		</Link>
	);
};

export default ParkInformation;
