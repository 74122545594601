/**
 * ParkListing - Map Area component
 */

import React from 'react';
import { IImage, ParkModel } from 'types/global';

type Props = {
	image: IImage;
	url: string;
	name: string;
	imageMapCoordinates: string;

	handleMouseEnter: (currentPark: ParkModel) => any;
	handleClickArea: (e: any, currentPark: ParkModel) => any;
	handleMouseLeave: () => any;
};

/** ParkListing - Map Area component component. */
const Area: React.FC<Props> = ({
	image,
	url,
	name,
	imageMapCoordinates,
	handleMouseEnter,
	handleMouseLeave,
	handleClickArea,
}) => {
	const mouseEnterEvent = () =>
		handleMouseEnter({
			image,
			url,
			name,
			imageMapCoordinates,
		});

	return (
		<area
			onClick={(e: any) =>
				handleClickArea(e, { image, url, name, imageMapCoordinates })
			}
			onMouseOver={mouseEnterEvent}
			onMouseOut={handleMouseLeave}
			data-original-radius="12"
			tabIndex={-1}
			shape="circle"
			coords={imageMapCoordinates}
			href={url}
			alt={name}
			data-name={name && name.toLowerCase()}
			data-display-name={name}
			data-image-src={image.src}
		/>
	);
};

export default Area;
