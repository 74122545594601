/**
 * PageFooter
 */

import Image from 'components/Image';
import React, { useEffect } from 'react';
import { ModelFooter, ModelResponsible } from 'types/epi';
import { Link } from 'types/global';
import PageFooterLink from './PageFooterLink';

export interface Props {
	fnFooterHeight: (val: number) => void;
	footerLinks: ModelFooter | undefined;
	responsible: ModelResponsible | undefined;
}

/** Page footer */
const PageFooter: React.FC<Props> = ({
	fnFooterHeight,
	footerLinks,
	responsible,
}) => {
	const targetRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (targetRef.current) {
			fnFooterHeight(targetRef.current.offsetHeight);
		}
	}, [responsible]);

	return (
		<>
			<footer
				ref={targetRef}
				className="bg-dim-grey flex flex-row flex-wrap justify-between p-5 gap-4"
			>
				<ul className="text-white text-sm">
					{footerLinks?.footerLinks.map((link: Link, i: number) => (
						<PageFooterLink href={link.url} key={i}>
							{link.text}
						</PageFooterLink>
					))}
				</ul>

				{responsible && (
					<div>
						{responsible.name && (
							<p
								dangerouslySetInnerHTML={{ __html: responsible.name }}
								className="text-sm text-white pb-3 font-medium"
							/>
						)}

						{responsible.logo && responsible.url ? (
							<a 
								title={responsible.name}
								target="_blank"
								href={responsible.url}
								rel="noopener noreferrer"
							>
								<Image
									className="h-20"
									src={responsible.logo.src}
									srcSet={responsible.logo.srcSet}
									srcSetSizes={responsible.logo.srcSetSizes}
									alt={responsible.logo.alt || ''}
									{...(!responsible.name && { 'aria-hidden': true })}
								/>
							</a>
						) : (
							responsible.logo && (
								<Image
									className="h-20"
									src={responsible.logo.src}
									srcSet={responsible.logo.srcSet}
									srcSetSizes={responsible.logo.srcSetSizes}
									alt={responsible.logo.alt || ''}
									{...(!responsible.name && { 'aria-hidden': true })}
								/>
							)
						)}
					</div>
				)}
			</footer>
		</>
	);
};

export default PageFooter;
