/**
 * Menu
 */

import React from 'react';
import MenuItem from './MenuItem';
import LargeMenuItem from './LargeMenuItem';
import { MenuModel } from 'types/epi';

/** Main description for this component. */
const Menu: React.FC<MenuModel> = ({ items }) => {
	return (
		<nav className="flex flex-col">
			<ul>
				{items?.map((item, i) => (
					<li key={i}>
						{i === 0 ? <LargeMenuItem {...item} /> : <MenuItem {...item} />}
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Menu;
