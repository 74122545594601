import React, { forwardRef } from 'react';

type State = {
	successMessage: string;
	invalidFields: string[];
	serverValidationError: string | boolean;
	validationMessages: any;
};

interface FormMessageProps {
	/** Message to display when successfully submitted */
	confirmationMessage: string | null;

	/** Current form state */
	state: State;

	/** FormMessage ref to set */
	ref: any;
}

interface ErrorSummaryProps {
	validationMessages: any;
}

const ErrorSummary: React.FC<ErrorSummaryProps> = ({ validationMessages }) => (
	<>
		<h2 className="text-base">
			Formuläret innehåller {Object.keys(validationMessages).length} fel:
		</h2>

		<div className="text-sm">
			<ul className="mt-0 pb-0">
				{validationMessages &&
					Object.keys(validationMessages).map((key, i) => (
						<li key={i} className="text-sm mb-0">
							<div
								dangerouslySetInnerHTML={{
									__html: validationMessages[key].label,
								}}
							/>
							<span>{validationMessages[key].message}</span>
						</li>
					))}
			</ul>
		</div>
	</>
);

const FormMessage: React.FC<FormMessageProps> = forwardRef(
	({ confirmationMessage, state }, ref: any) => {
		const {
			successMessage,
			invalidFields,
			serverValidationError,
			validationMessages,
		} = state;
		const hasMessageToShow = confirmationMessage || successMessage;
		const messageType =
			serverValidationError || invalidFields.length > 0 ? 'error' : 'success';

		return (
			<div
				className="my-6"
				hidden={messageType === 'success' && !hasMessageToShow}
			>
				<div
					className={`pt-3 px-3 border-solid border-2  rounded-lg ${
						messageType === 'success' ? 'border-green-400' : 'border-red-400'
					}`}
					tabIndex={-1}
					ref={ref}
				>
					{(serverValidationError || invalidFields.length > 0) && (
						<ErrorSummary validationMessages={validationMessages} />
					)}

					{confirmationMessage && <p>{confirmationMessage}</p>}
					{successMessage && (
						<div dangerouslySetInnerHTML={{ __html: successMessage }} />
					)}
				</div>
			</div>
		);
	}
);

export default FormMessage;
