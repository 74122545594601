import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface HomeModel {
	url: string;
	alt: string;
	logo: string;
}

/** Main description for this component. */
const Home: React.FC<HomeModel> = ({ url, alt, logo }) => {
	return (
		<RouterLink to={url} className="my-2 mr-8">
			<img className="h-10 lg:h-12" src={logo} alt={alt} />
		</RouterLink>
	);
};

export default Home;
