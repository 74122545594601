/**
 * MenuItem
 */

import React from 'react';
import clsx from 'clsx';
import { MenuItemModel } from 'types/epi';
import Link from 'components/Link';

const MenuItem: React.FC<MenuItemModel> = ({ text, url, selected }) => {
	if (!selected) {
		return (
			<Link
				to={url}
				className={clsx('block px-2 py-1 hover:bg-grey-lighter', 'border-t')}
			>
				<span className="w-full h-full">
					<p className={clsx('text-sm font-bold')}>{text}</p>
				</span>
			</Link>
		);
	} else {
		return (
			<p className="block px-2 py-1 hover:bg-grey-lighter bg-yellow w-full h-full text-sm font-bold">
				{text}
			</p>
		);
	}
};

export default MenuItem;
