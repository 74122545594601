/**
 * RouteHandler
 */

import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Text from 'components/Text';
import { PagesMapper } from 'pages';
import ErrorPage404 from 'pages/ErrorPage404';
import Loader from 'components/Loader';
import Layout from 'layouts';
import { selectModel, selectContent, fetchPage } from 'store/modules/model';
import { updateRenderingState, selectReact } from 'store/modules/react';
import { RenderingStates } from 'types/epi';
import { useMediaQuery } from 'react-responsive';

import { useLocation } from 'react-router-dom';
import { selectEpi } from 'store/modules/epi';

/** Loads data and renders the correct page based on the route. */
const RouteHandler: React.FC = () => {
	let { siteRoute } = useParams();
	siteRoute = siteRoute || '/';
	const ariaLiveRef = useRef<HTMLDivElement>(null);
	const emptyFocusDivRef = useRef<HTMLDivElement>(null);
	const pageRef = useRef<any>(null);
	const { action } = useHistory();
	const dispatch = useDispatch();
	const { error, loading } = useSelector(selectModel);
	const { inEditMode } = useSelector(selectEpi);
	const pageContent = useSelector(selectContent);
	const { renderingState, apiUrl } = useSelector(selectReact);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const Page = PagesMapper(pageContent);
	const isTablet = useMediaQuery({ query: '(max-width: 767px)' });
	const location = useLocation();

	// Fix siteRoute so we don't request '//'.
	if (
		apiUrl &&
		siteRoute &&
		apiUrl.charAt(apiUrl.length - 1) === '/' &&
		siteRoute.charAt(0) === '/'
	) {
		siteRoute = siteRoute.substr(1);
	}

	// Load the page
	useEffect(() => {
		// First hydrate rendering we wont be doing any request, becase we already have the data from the SSR.
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			return;
		}

		dispatch(fetchPage(apiUrl, siteRoute));
		// eslint-disable-next-line
	}, [apiUrl, siteRoute, dispatch]);

	// Set renderingState to clientside after the first (hydration) render.
	useEffect(() => {
		dispatch(updateRenderingState(RenderingStates.ClientSide));
		// eslint-disable-next-line
	}, []);

	// If we have been waiting for the response more than 400ms we display the loader
	useEffect(() => {
		let loaderTimeout: any;

		if (loading) {
			loaderTimeout = setTimeout(() => {
				// Tell sighted users
				setLoaderVisible(true);
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = 'Sidan laddar';
				}
				setTimeout(() => {
					if (ariaLiveRef.current) {
						ariaLiveRef.current.innerHTML = '';
					}
				}, 500);
			}, 400);
		} else {
			setLoaderVisible(false);
			if (action === 'PUSH') {
				window.scrollTo(0, 0);
			}
		}

		return () => {
			if (loaderTimeout) {
				clearTimeout(loaderTimeout);
			}
		};
		// eslint-disable-next-line
	}, [loading]);

	// Page is loaded
	/*useEffect(() => {
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			return;
		}

		if (pageContent && !inEditMode) {
			if (ariaLiveRef.current) {
				ariaLiveRef.current.innerHTML = 'Sidan har laddats';
			}
			setTimeout(() => {
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = '';
				}
			}, 500);

			if (emptyFocusDivRef.current) {
				let x = window.scrollX;
				let y = window.scrollY;
				emptyFocusDivRef.current.focus();
				window.scrollTo(x, y);
			}
			setTimeout(() => {
				if (pageRef.current) {
					let x = window.scrollX;
					let y = window.scrollY;
					const firstH1 = pageRef.current.querySelector('h1');
					if (firstH1) {
						firstH1.setAttribute('tabindex', -1);
						firstH1.style.outline = 'none';
						firstH1.focus();
					} else {
						pageRef.current.focus();
					}
					window.scrollTo(x, y);
				}
			}, 1000);
		}
	}, [pageContent]);*/

	useEffect(() => {
		// This is to updated epi quicknavigator link with current page id
		var epiNavigator: HTMLAnchorElement | null = document.querySelector(
			'#epi-quickNavigator > li:first-of-type > a:first-of-type'
		);
		if (epiNavigator && pageContent && epiNavigator.href) {
			const prefix = ':///';
			const pos = epiNavigator.href.lastIndexOf(prefix);

			if (pos !== -1) {
				var pageEditLink =
					epiNavigator.href.substring(0, pos) + prefix + pageContent.id;
				epiNavigator.href = pageEditLink;

				// This is to updated epi quicknavigator dropdown menu link with current page id
				var epiNavigatorMenuItem: HTMLAnchorElement | null = document.querySelector(
					'#epi-quickNavigator-menu > li:last-of-type > a:first-of-type'
				);
				if (epiNavigatorMenuItem) {
					epiNavigatorMenuItem.href = pageEditLink;
				}
			}
		}
	}, [location, pageContent]);

	return (
		<>
			{loading && loaderVisible && (
				<div className="z-50 absolute w-full h-screen flex justify-center items-center">
					<Loader variant={isTablet ? 'mini' : 'default'} />
				</div>
			)}
			{error && error.indexOf('404') !== -1 && (
				<ErrorPage404 modelType={'SNPErrorPage404'} />
			)}
			{pageContent && !error && (
				<div ref={pageRef} tabIndex={-1} style={{ outline: 'none' }}>
					<Layout>
						<Page {...pageContent} />
					</Layout>
				</div>
			)}
			<Text as="screenReader" aria-live="assertive" ref={ariaLiveRef}></Text>
			<div
				tabIndex={-1}
				ref={emptyFocusDivRef}
				style={{ outline: 'none' }}
			></div>
		</>
	);
};
export { RouteHandler };
