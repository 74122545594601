/**
 * AutoComplete
 */

import React, { useEffect } from 'react';
import Downshift from 'downshift';
import { useAutoComplete } from 'hooks';
import clsx from 'clsx';

import SearchField from 'components/SearchField';
import SearchFieldLarge from 'components/SearchField/SearchFieldLarge';
import { Form } from 'components/Form';

interface Props {
	id: string;
	label: string;

	/** Placeholder */
	placeholder?: string;

	/** Destination to send searched words */
	action?: string;

	/** Field name */
	name: string;

	/** Submit button text */
	submitText: string;

	/** Autocomplete url */
	autocompleteApiUrl?: string;

	/** Optional on change and on submit functions */
	onSubmit?: any;
	onChange?: any;

	/** Field value */
	value?: string;

	/** If the Autocomplete is for the large search (main search on search page) or not */
	largeSearch?: boolean;
}

/** Auto complete component */
const Autocomplete: React.FC<Props> = ({
	id,
	label,
	placeholder,
	action,
	name,
	submitText,
	autocompleteApiUrl,
	onSubmit,
	onChange,
	value,
	largeSearch,
}) => {
	const [setQuery, setSuggestions, query, suggestions] = useAutoComplete(
		autocompleteApiUrl || ''
	);

	const handleClearSuggestions = () => {
		setQuery('');
		setSuggestions();
	};

	const handleSubmit = (value: any) => {
		const textValue = value.text ? value.text : value;
		setQuery(textValue);

		if (onSubmit) {
			onSubmit(textValue);
		} else {
			const url = `${action}?${name}=${textValue.split(' ').join('+')}`;
			window.location.href = url;
		}
	};

	const handleInputValueChange = (inputValue: string, stateAndItems: any) => {
		const { selectedItem } = stateAndItems;

		switch (stateAndItems.type) {
			case Downshift.stateChangeTypes.changeInput:
				if (!inputValue && selectedItem && !onSubmit) {
					handleSubmit(selectedItem);
				} else {
					if (
						(!selectedItem || selectedItem.text !== inputValue) &&
						inputValue !== ''
					) {
						setQuery(inputValue);
						if (onChange) onChange(inputValue);
					}
					if ((inputValue === '' || !inputValue) && selectedItem) {
						stateAndItems.clearSelection();
						handleClearSuggestions();
						setQuery(selectedItem.text);
						if (onChange) onChange(selectedItem.text);
					}
					if (
						inputValue === '' ||
						(selectedItem === null && inputValue === '')
					) {
						handleClearSuggestions();
						if (onChange) onChange('');
					}
				}
				break;
			case Downshift.stateChangeTypes.clickItem:
			case Downshift.stateChangeTypes.keyDownEnter:
				handleSubmit(selectedItem);
				break;
			default:
				return null;
		}
	};

	useEffect(() => {
		return () => {
			handleClearSuggestions();
		};
	}, []);

	return (
		<Downshift
			onInputValueChange={handleInputValueChange}
			itemToString={(item) => (item && item.text) || query || ''}
			initialInputValue={query}
		>
			{({
				getLabelProps,
				getMenuProps,
				getInputProps,
				getItemProps,
				openMenu,
				closeMenu,
				isOpen,
				highlightedIndex,
			}) => (
				<div className="relative">
					{largeSearch ? (
						<SearchFieldLarge
							onSubmit={() => {
								handleSubmit(query);
								closeMenu();
							}}
							{...getInputProps()}
							id="main-search"
							placeholder={placeholder}
							name="query"
							label={label}
							value={value}
							labelProps={{ ...getLabelProps() }}
							onFocus={openMenu}
							submitText="Sök"
						/>
					) : (
						<>
							<label className="sr-only" {...getLabelProps()} htmlFor={id}>
								{label}
							</label>
							<Form action="POST" onSubmit={() => handleSubmit(query)}>
								<SearchField
									{...getInputProps()}
									id={id}
									placeholder={placeholder}
									name={name}
									label={label}
									onFocus={openMenu}
									submitText={submitText}
								/>
							</Form>
						</>
					)}

					{isOpen && suggestions && suggestions.length > 0 && (
						<div
							className={clsx(
								'search-suggestions',
								'absolute',
								'block',
								'text-sm',
								'shadow-xl',
								'rounded-b',
								'z-50',
								'overflow-hidden',
								'bg-white',
								'py-1',
								'px-1',
								largeSearch && 'left-0 right-0 top-9'
							)}
						>
							<ul {...getMenuProps()}>
								{suggestions.map((item: any, index: number) => (
									<li
										{...getItemProps({
											key: `${item.text}_${index}`,
											index,
											item,
											selected: highlightedIndex === index,
										})}
										className={clsx(
											'cursor-pointer',
											'overflow-hidden',
											'hover:underline',
											'hover:bg-grey-lighter',
											'hover:text-blue'
										)}
									>
										<span
											dangerouslySetInnerHTML={{
												__html: item.html,
											}}
										/>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</Downshift>
	);
};
export default Autocomplete;
