/**
 * ParkListing - Park
 */

import clsx from 'clsx';
import Image from 'components/Image';
import LinkComponent from 'components/Link';
import React from 'react';
import { IImage } from 'types/global';

type Props = {
	name: string;
	image: IImage;
	url: string;
};

/** Park component. */
const Park: React.FC<Props> = ({ name, image, url }) => (
	<LinkComponent
		href={url}
		className={clsx(
			'w-full',
			'inline-block',
			'bg-white',
			'shadow-md',
			'hover:shadow-3xl'
		)}
	>
		<div className="relative">
			{image && (
				<Image 
				className="img-park object-cover"
				src={image.src}
				srcSet={image.srcSet}
				srcSetSizes={image.srcSetSizes}
				alt={image.alt}
				/>
			)}

			<div className="flex items-center w-full py-2 pl-2 pr-4">
				<img
					className="park-logo"
					src="/assets/images/blaljung_logo.png"
					alt=""
					aria-hidden={true}
				/>
				<p className="text-sm font-normal text-black ml-2 mr-2 break-words">
					{name}
				</p>
			</div>
		</div>
	</LinkComponent>
);

export default Park;
