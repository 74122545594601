/**
 * EpiContentArea
 */

import clsx from 'clsx';
import ActivitesPuff from 'components/ActivitesPuff';
import {
	CurrentInformationBlock,
	InfoBlock,
} from 'components/CurrentInformationBlock';
import { Cell, Grid } from 'components/Grid';
import Instagram from 'components/Instagram';
import NewsletterPuff from 'components/NewsletterPuff';
import ParkInformation from 'components/ParkInformation';
import ParkListing from 'components/ParkListing';
import Puff from 'components/Puff';
import PuffWithImage from 'components/PuffWithImage';
import StandardBlock from 'components/StandardBlock';
import VideoBlock from 'components/VideoBlock';
import React from 'react';
import {
	ActivitiesPuffModel,
	ArticlesPuffModel,
	CurrentInformationItemModel,
	CurrentInformationsBlockModel,
	VideoBlockModel,
} from 'types/blocks';

interface Props {
	/** Epi property containing content for the content area */
	content?: any;

	/** Epi properties for on page editing */
	[htmlAttributes: string]: any;
}

export const renderContent = (
	block:
		| VideoBlockModel
		| ArticlesPuffModel
		| ActivitiesPuffModel
		| CurrentInformationsBlockModel
		| any,
	i: number
) => {
	switch (block.modelType) {
		case 'ActivitesPuff':
		case 'ArticlesPuff':
			const items = block.activities || block.articles;
			let width: string;
			if (items === block.articles) {
				width = items.length > 3
				? 'xs:w-1/2 md:w-1/3 lg:w-1/4'
				: items.length < 3
				? 'xs:w-1/2'
				: 'xs:w-1/2 md:w-1/3';
			}

			if (items === block.activities) {
				width =
					items.length > 3
						? 'md:w-1/3 lg:w-1/4'
						: items.length < 3
						? 'md:w-1/2'
						: 'md:w-1/3';
			}

			return (
				<Cell className="rssnp-readarea" span={12} key={i}>
					<ActivitesPuff {...block}>
						{items?.map((activity: any, index: number) => (
							<div className={`w-full ${width}`}>
								<StandardBlock
									heading={activity.name || activity.heading}
									{...activity}
									key={index}
									isPark
								/>
							</div>
						))}
					</ActivitesPuff>
				</Cell>
			);
		case 'Puff':
			return (
				<Cell className="rssnp-readarea" span={12} key={i}>
					<Puff {...block} />
				</Cell>
			);
		case 'PuffWithImage':
			return (
				<Cell span={12} key={i}>
					<PuffWithImage {...block} />
				</Cell>
			);
		case 'NewsletterPuff':
			return (
				<Cell span={12} key={i}>
					<NewsletterPuff {...block} />
				</Cell>
			);
		case 'ParkInformation':
			return (
				<Cell span={12} key={i}>
					<ParkInformation {...block} />
				</Cell>
			);
		case 'CurrentInformationsBlockModel':
			return (
				<Cell span={12} key={i}>
					<CurrentInformationBlock
						itemContainerClassName={clsx(
							'max-w-4xl',
							block?.items?.length > 2 && 'max-w-full',
							block?.items?.length > 1 &&
								block?.items?.length <= 2 &&
								'max-w-5xl'
						)}
						{...block}
					>
						<Grid
							className="flex-col items-center mx-0 my-2 md:max-w-screen-xl md:flex-row md:items-stretch flex-nowrap m-2 p-2"
							margin={false}
							padding={false}
						>
							{block.items?.map(
								(item: CurrentInformationItemModel, j: number) => {
									return (
										<Cell
											margin={false}
											key={`${i}_item_${j}`}
											className={`${
												block?.items?.length > 1
													? 'puff-tablet-width m-3'
													: 'info-block-single-image'
											} `}
										>
											<InfoBlock
												{...item}
												layout={
													block?.items?.length === 1 ? 'horizontal' : 'vertical'
												}
											/>
										</Cell>
									);
								}
							)}
						</Grid>
					</CurrentInformationBlock>
				</Cell>
			);
		case 'InstagramModel':
			return (
				<Cell
					span={12}
					key={i}
					className={clsx(
						block?.colorTheme === "grey-lighter" ? "bg-grey-lighter" : "bg-white"
					)}
				>
					<Instagram {...block} />
				</Cell>
			);
		case 'Parks':
			return (
				<Cell span={12} key={i}>
					<ParkListing {...block} />
				</Cell>
			);
		case 'VideoBlock':
			return (
				<Cell span={12} key={i}>
					<VideoBlock {...block} />
				</Cell>
			);
		default:
			return null;
	}
};

/** Epi content area for handling blocks */
const EpiContentArea: React.FC<Props> = ({
	content = [],
	...htmlAttributes
}) => (
	<div {...htmlAttributes}>
		<Grid margin={false} padding={false}>
			{content?.map(renderContent)}
		</Grid>
	</div>
);

export default EpiContentArea;
