/**
 * StandardPuffBlock
 */

import React from 'react';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Link from 'components/Link';
import clsx from 'clsx';
import { IImage } from '../../types/global';
import Image from '../Image';

interface Props {
	heading: string;
	text: string;
	url: string;
	colorTheme: "white" | "grey-lightest" | "yellow-light";
	image: IImage;
}

/** Standard puff block component. */
const PuffWithImage: React.FC<Props> = ({
	heading,
	text,
	url,
	image,
	colorTheme = "white",
}) => {
	return (
		<div className={clsx(
			colorTheme === "white" && "bg-white",
			colorTheme === "grey-lightest" && "bg-grey-lightest",
			colorTheme === "yellow-light" && "bg-yellow-light"
		)}>
			<div className="text-center max-w-2xl pt-16 pb-4 mx-auto ">
				<Link href={url} className="focus:outline-solid-orange block">
					<div className="share-list">
						{image && (
							<div className={clsx('mx-auto mb-3 rounded')}>
								<Image
									className="mx-auto max-w-puffImages"
									src={image.src}
									alt={image.alt}
									aria-hidden="true"
									loading="lazy"
									decoding="async"
									srcSet={image.srcSet}
									srcSetSizes={image.srcSetSizes}
								/>
							</div>
						)}

						{heading && (
							<Text as="xl3">
								<Icon direction={'left'} icon={'chevron'} aria-hidden="true" />{' '}
								{heading}
							</Text>
						)}

						{text && (
							<Text as="preamble" className="leading-5 mb-8 mt-4 px-5 sm:px-0">
								{text}
							</Text>
						)}
					</div>
				</Link>
			</div>
		</div>
	);
};

export default PuffWithImage;
