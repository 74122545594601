/**
 * Grid
 */

import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { getWidthClass, getWidthClassDesktop, getWidthClassTablet } from './CssHelper';

// Interfaces
interface GridProps {
	/** Option to set max-width on the inner component */
	maxWidth?: boolean;

	/** Option to remove default grid padding */
	padding?: boolean;

	/** Option to remove default grid margin */
	margin?: boolean;

	/** Pass optional classnames for the html */
	className?: string;

	/** Pass optional inline styling for the html */
	style?: CSSProperties;

	/** Pass optional attributes for the grid */
	[htmlAttributes: string]: any;
}

interface CellProps {
	/** Specifies the number of columns the cell spans */
	span?: number;

	/** Specifies the number of columns the cell spans on a type of device (desktop) */
	desktop?: number;

	/** Specifies the number of columns the cell spans on a type of device (tablet) */
	tablet?: number;

	/** Pass optional classnames for the html */
	className?: string;

	/** Option to remove default grid margin */
	margin?: boolean;

}


const renderChildren = (children: any, margin: boolean) => {
	const renderedChildren = React.Children.map(children, (child) => {
		const props: any = child && child.props ? { ...child.props } : {};
		props.margin = margin;
		if (child) {
			return React.cloneElement(child, props);
		} else return false;
	});

	return renderedChildren;
};

/** Grid component using tailwind classes. */
const Grid: React.FC<GridProps> = ({
	padding = true,
	margin = true,
	maxWidth = false,
	className,
	style,
	children,
	...htmlAttributes
}) => {
	return (
		<div className={clsx(maxWidth && 'mx-auto max-w-site')}>
			{style ? (
				<div
					className={clsx(
						'flex flex-wrap md:justify-between lg:justify-start',
						margin && '-ml-4',
						padding && 'lg:py-6 lg:pl-6 py-4 pl-4',
						className
					)}
					style={style}
					{...htmlAttributes}
				>
					{renderChildren(children, margin)}
				</div>
			) : (
				<div
					className={clsx(
						'flex flex-wrap md:justify-between lg:justify-start',
						margin && '-ml-4',
						padding && 'lg:py-6 lg:pl-6 py-4 pl-4',
						className
					)}
					{...htmlAttributes}
				>
					{renderChildren(children, margin)}
				</div>
			)}
		</div>
	);
};

/** Grid Cell component using tailwind classes. */
const Cell: React.FC<CellProps> = ({
	span,
	desktop,
	tablet,
	className,
	margin = true,
	children,
}) => {
	return (
		<div
			className={clsx(
				className,
				margin && 'mx-4',
				span && getWidthClass(span, margin),
				desktop && `${getWidthClassDesktop(desktop, margin)}`,
				tablet && `${getWidthClassTablet(tablet, margin)}`,
			)}
		>
			{children}
		</div>
	);
};

export { Grid, Cell };
