/**
 * ParkListing - Park
 */

import React from 'react';
import LinkComponent from 'components/Link';
import Icon from 'components/Icon';
import clsx from 'clsx';
import { IImage } from 'types/global';
import Image from 'components/Image';


type Props = {
	image: IImage;
	url: string;
	name: string;
	canHoverOverlay?: boolean;
	isPhone: boolean;
	showSmallImage?: boolean;
	border?: boolean;
};

/** Park component. */
const Park: React.FC<Props> = ({
	image,
	url,
	name,
	canHoverOverlay,
	border = false,
	showSmallImage,
	isPhone,
	children,
}) => (
	<>
		{!isPhone && (
			<>
				

				{showSmallImage && !canHoverOverlay && (
					<div
						style={{
							backgroundImage: `url(${image})`,
						}}
						className="img-wrap"
					>
						<Image
							src={image.src}
							alt={image.alt}
							srcSet={image.srcSet}
							srcSetSizes={image.srcSetSizes}
							aria-hidden={true}
							className={clsx(
								"object-cover",
								`${showSmallImage ? 'w-20' : 'w-full'}`,
								`${showSmallImage ? 'h-20' : 'auto'}`
							)}
						/>
					</div>
				)}
				{!showSmallImage && (
					<Image
						src={image.src}
						alt={image.alt}
						srcSet={image.srcSet}
						srcSetSizes={image.srcSetSizes}
						aria-hidden={true}
						className={clsx(
							`display: ${!canHoverOverlay ? 'block' : 'hidden'}`,
							`width: ${showSmallImage ? 'w-20' : 'w-full'}`,
							`height: ${showSmallImage ? 'h-20' : 'auto'}`
						)}
					/>
				)}
				{children}
			</>
		)}

		<a
			{...(!canHoverOverlay && { href: url })}
			className={`inline-flex items-center justify-between w-full py-2 pl-2 pr-4 ${border ? "border-gray-700 border focus:border-white": ""}`}
		>
			<div className="md:flex items-center">
				<img
					className="park-logo"
					src="/assets/images/blaljung_logo.png"
					alt=""
					aria-hidden={true}
				/>
				{!canHoverOverlay && showSmallImage ?
					<h3 className="text-sm font-normal text-black ml-2 mr-2 break-words">
						{name}
					</h3> :
					<span className="text-sm font-normal text-black ml-2 mr-2 break-words">
						{name}
					</span>
				}
			</div>

			{!canHoverOverlay && 
				<Icon direction={'left'} icon={'chevron'} aria-hidden="true" />
			}
			
			{!showSmallImage &&
				<svg className='map-overlay-arrow absolute' width="100%" viewBox="0 0 200 10">
					<polygon className='stroke-gray-700 fill-white' strokeWidth={.5} strokeDasharray="0 30 28.284" fill="white" points="30,0,0,0 14,10" />
				</svg>
			}
		</a>
	</>
);

export default Park;
