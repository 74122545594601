/**
 * CurrentInformationBlock
 */

import clsx from 'clsx';
import Button from 'components/Button';
import Text from 'components/Text';
import React, { HTMLAttributes } from 'react';
import { Link } from 'types/global';

interface Props {
	/** Block heading */
	heading: string;

	/** Block preamble */
	preamble?: string;

	/** Block link */
	link?: Link;

	/** Classname for setting the backgroundcolor */
	colorTheme?: "white" | "grey-lightest" | "yellow-light";

	/** Epi properties for on-page editing */
	_properties?: any;

	/** Classes for the container holding the children */
	itemContainerClassName?: Pick<HTMLAttributes<HTMLDivElement>, 'className'>;
}

/** Current informations component. */
const CurrentInformationBlock: React.FC<Props> = ({
	heading,
	preamble,
	link,
	colorTheme = "white",
	_properties,
	children,
	itemContainerClassName,
}) => {
	return (
		<div className={clsx(
			"flex",
			"justify-center",
			colorTheme === "white" && "bg-white",
			colorTheme === "grey-lightest" && "bg-grey-lightest",
			colorTheme === "yellow-light" && "bg-yellow-light"
		)}>
			<div
				className={clsx(
					itemContainerClassName,
					'text-center max-w-4xl py-16 mx-auto'
				)}
			>
				<Text
					as="h2"
					{..._properties?.heading}
					className="leading-7 mb-8 font-normal breaking-words mx-4 sm:mx-0"
				>
					{heading}
				</Text>

				{preamble && (
					<Text
						as="preamble"
						{..._properties?.preamble}
						className="text-lg preamble-text-width m-auto px-5 sm:px-0"
					>
						{preamble}
					</Text>
				)}
				{children}
				{link && link.url && (
					<Button size={'medium'} as={'a'} href={link.url} variant="primary">
						{link.text}
					</Button>
				)}
			</div>
		</div>
	);
};

export default CurrentInformationBlock;
