import React from 'react';
import { useSelector } from 'react-redux';
import { selectBreadcrumbs } from 'store/modules/model';

import Component from './Breadcrumbs';
import Crumb from './Crumb';

const Breadcrumbs = () => {
	const model = useSelector(selectBreadcrumbs);

	return (
		<Component label={model?.label}>
			{model?.breadcrumbs.map((link, i) => (
				<Crumb href={link.url} active={link.isActive} key={i}>
					{link.text}
				</Crumb>
			))}
		</Component>
	);
};

export { Breadcrumbs, Crumb };
