import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MinBreakpoint } from 'theme/media-queries';
import { useSelector } from 'react-redux';
import { selectFooterLinks, selectResponsible } from 'store/modules/model';
import DesktopHeader from 'components/DesktopHeader';
import GoTopButton from 'components/GoTopButton';
import Meta from 'components/Meta';
import SkipToMain from 'components/SkipToMain';
import MobileHeader from 'components/MobileHeader';
import { PageFooter } from 'components/PageFooter';

const Layout: React.FC = ({ children }) => {
	const footerLinks = useSelector(selectFooterLinks);
	const responsible = useSelector(selectResponsible);

	const isDesktop = useMediaQuery({
		minWidth: MinBreakpoint.desktop,
	});
	const [height, setHeight] = React.useState(0);

	const handleCallback = (val: number) => {
		setHeight(val);
	};

	return (
		<>
			<Meta />
			{!isDesktop && <MobileHeader />}

			{isDesktop && (
				<React.Fragment>
					<SkipToMain />
					<DesktopHeader />
				</React.Fragment>
			)}

			<main id="main-content">{children}</main>

			<GoTopButton footerHeight={height} />
			<PageFooter
				footerLinks={footerLinks}
				responsible={responsible}
				fnFooterHeight={handleCallback}
			/>
		</>
	);
};

export default Layout;
