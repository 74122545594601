import React from 'react';
import { useSelector } from 'react-redux';
import { selectNavigation } from 'store/modules/model';

import Component from './MobileMenu';

interface Props {
	onClose?: any;
	onLinkClick?: any;
}

const MobileMenu: React.FC<Props> = ({ onClose, onLinkClick }) => {
	const navigation: any = useSelector(selectNavigation);

	return (
		<Component {...navigation} onClose={onClose} onLinkClick={onLinkClick} />
	);
};

export default MobileMenu;
