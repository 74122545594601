/**
 * MobileMenu - Ul
 */

import clsx from 'clsx';
import React from 'react';
import { Link } from 'types/global';
import Li from './MobileMenu.Li';

interface PaneProps {
	/** Links for the pane structured as JSON */
	links: Link[];

	/** The pane level (1, 2, 3) */
	level: number;

	/** Determine if childelement or not for indent */
	isChild?: boolean;

	/** Screenreadertext for mobile (toggle open) */
	openNavigationItemLabel?: string;

	closeNavigationItemLabel?: string;

	/** Callback method when the menu closes */
	onClose?: any;

	/** Callback method when user clicks in a link */
	onLinkClick?: any;
}

/** A ul used by MobileMenu */
const Ul: React.FC<PaneProps> = ({
	links,
	level,
	isChild,
	onClose,
	onLinkClick,
	openNavigationItemLabel,
	closeNavigationItemLabel,
}) => (
	<ul
		className={clsx(
			'text-sm lg:text-base text-white',
			level > 1 && 'bg-grey-darkest'
		)}
	>
		{links.map((link, index) => (
			<Li
				link={link}
				level={level}
				key={index}
				index={index}
				isChild={isChild}
				onClose={onClose}
				onLinkClick={onLinkClick}
				openNavigationItemLabel={openNavigationItemLabel}
				closeNavigationItemLabel={closeNavigationItemLabel}
			/>
		))}
	</ul>
);

export default Ul;
