/**
 * EpiFragments
 */

import React from 'react';
import { EpiFormsProps } from 'components/EpiForms/EpiForms';

import {
	FragmentModelTypes,
	RawFragmentModel,
	HeadingFragmentModel,
	ImageFragmentModel,
	TableFragmentModel,
	VideoFragmentModel,
} from 'types/fragments';
import Image from 'components/Image';
import RichText from 'components/RichText';
import EpiForms from 'components/EpiForms';
import Text from 'components/Text';
import ResponsiveIframe from 'components/ResponsiveIframe';
import VideoBlock from 'components/VideoBlock/VideoBlock';
import { VideoBlockModel } from 'types/blocks';
import clsx from 'clsx';
import EditableField from 'components/EditableField';

interface Props {
	/** Epi properties to use for on page editing */
	[htmlAttributes: string]: any;

	/** Fragments that should be rendered. */
	fragments?: FragmentModelTypes[];

	isTablet?: boolean;
}

const getHeadingLevel = (level: number) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		case 6:
			return 'h6';
		default:
			return 'h2';
	}
};

const getFragment = (fragment: FragmentModelTypes, index: number) => {
	switch (fragment.modelType) {
		case 'HeadingFragment':
			const data = fragment as HeadingFragmentModel;
			return (
				<Text
					className={'my-3'}
					as={getHeadingLevel(data.level)}
					id={data.id}
					key={index}
				>
					<span
						className="breaking-words"
						dangerouslySetInnerHTML={{ __html: data.raw }}
					/>
				</Text>
			);
		case 'Image':
			const imgData = fragment as ImageFragmentModel;

			return (
				<Image
					className={clsx(
						(imgData.alignment && !imgData.caption) && [
							"my-4 mt-0 sm:mx-5",
							imgData.alignment === "left" ? "sm:float-left sm:ml-0" : "sm:float-right sm:mr-0",
							imgData.size === "smaller" && "sm:w-image-smaller",
							imgData.size === "small" && "sm:w-image-small",
							imgData.size === "medium" && "sm:w-image-medium",
							imgData.size === "large" && "sm:w-image-large",
							!imgData.size && "sm:w-2/4"
						]
					)}
					figClassName={clsx(
						(imgData.alignment && imgData.caption) && [
							"my-4 mt-0 sm:mx-5",
							imgData.alignment === "left" ? "sm:float-left sm:ml-0" : "sm:float-right sm:mr-0",
							imgData.size === "smaller" && "sm:w-image-smaller",
							imgData.size === "small" && "sm:w-image-small",
							imgData.size === "medium" && "sm:w-image-medium",
							imgData.size === "large" && "sm:w-image-large",
							!imgData.size && "sm:w-2/4"
						]
					)}
					src={imgData.src}
					srcSet={imgData.srcSet}
					link={imgData.link}
					srcSetSizes={imgData.srcSetSizes}
					alt={imgData.alt}
					figCaption={imgData.caption}
					key={index}
				/>
			);

		case 'Video':
			const videoData = fragment as VideoFragmentModel;

			return (
				<div className="mb-4" key={index}>
					<ResponsiveIframe
						src={videoData.embedUrl}
						aria-label={videoData.ariaLabel}
					/>
				</div>
			);

		case 'TableFragment':
			const tableData = fragment as TableFragmentModel;
			return (
				<div className="my-10" key={index}>
					<div dangerouslySetInnerHTML={{ __html: tableData.raw }} />
				</div>
			);
		case 'FormContainer':
			const formContainer = fragment as EpiFormsProps;

			return (
				<div className="mt-10 mb-24" key={index}>
					<EpiForms {...formContainer} />
				</div>
			);
		case 'RawFragment':
			const rawData = fragment as RawFragmentModel;

			return (
				<div key={index}>
					<RichText>
						<div
							className="richtextcontent mt-3 font-benton pb-3 px-6 lg:px-0"
							dangerouslySetInnerHTML={{
								__html: rawData.raw,
							}}
						/>
						<div className="clear-both"></div>
					</RichText>
				</div>
			);
		case 'VideoBlock':
			const videoBlockModel = fragment as VideoBlockModel;

			return (
				<VideoBlock
					videoUrl={videoBlockModel.videoUrl}
					width={videoBlockModel.width}
					height={videoBlockModel.height}
				/>
			);
		default:
			return null;
	}
};

/** Component for rendering Episerver fragments. */
const EpiFragments: React.FC<Props> = ({
	fragments,
	isTablet,
}) => {
	

	return (
		<EditableField field="text">
			<div className={`flow-root`}>
				{fragments?.map(getFragment)}
			</div>
		</EditableField>
	);
};

export default EpiFragments;
