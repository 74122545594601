/**
 * Loader
 * TODO: Variant = breaking changes
 */

import React from 'react';
import clsx from 'clsx';

interface props {
	/** Variant of loader */
	variant?: 'default' | 'static' | 'mini';

	/** Pass optional classnames for the html */
	classname?: string;

	[htmlattributes: string]: any;
}

const Loader: React.FC<props> = ({
	className = '',
	variant = 'default',
	...htmlAttributes
}) => {
	return (
		<div
			className={clsx(
				className,
				'loader',
				'rounded-full',
				'animate-spin',
				variant === 'static' && 'fixed top-0 left-0 m-auto',
				variant === 'mini' ? 'w-6 h-6 loader-mini' : 'w-40 h-40'
			)}
			{...htmlAttributes}
		/>
	);
};

export default Loader;
