
export const useWebpSupport = () => {
    let useWebp = false;
    if (typeof document === 'object') {
        const canvas = document.createElement('canvas');
    
        if (
            canvas.getContext &&
            canvas.getContext('2d') &&
            canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
        ) {
            useWebp = true;
        }
    }
    
    return useWebp;
}