/**
 * Notification
 */

import clsx from 'clsx';
import Icon from 'components/Icon';
import React, { useMemo } from 'react';
import { IconList } from 'theme/icons';

export type NotificationType = 'success' | 'error' | 'info' | 'warning';

export interface NotificationProps {
	isLoading?: boolean;
	type?: NotificationType;
	hidden?: boolean;
}

/** Main description for this component. */
const Notification: React.FC<NotificationProps> = ({
	isLoading = false,
	type = 'info',
	hidden = true,
	children,
}) => {
	const theme = useMemo(() => {
		// let componentTheme: { style: string; icon: IconList['icon'] };
		let icon: IconList['icon'];
		let style: string;

		switch (type) {
			case 'success':
				icon = 'check';
				style = 'bg-green-dark text-white';
				break;

			case 'error':
				icon = 'exclamation';
				style = 'bg-red-dark text-white';
				break;

			case 'warning':
				icon = 'info';
				style = 'bg-yellow-dark text-white';
				break;

			case 'info':
			default:
				style = 'bg-blue text-white';
				icon = 'info';
				break;
		}

		return {
			style,
			icon,
		};
	}, [type]);

	if (hidden) {
		return null;
	}

	return (
		<div className={clsx('p-4 rounded', theme.style)}>
			<div className="flex items-center gap-4">
				<Icon
					icon={isLoading ? 'spinner' : theme.icon}
					animate={isLoading ? 'spin' : 'none'}
				/>
				{!isLoading && <p className="font-bold">{children}</p>}
			</div>
		</div>
	);
};

export default Notification;
