/**
 * Button
 */

import React from 'react';
import clsx from 'clsx';

type Tags = 'a' | 'button';

interface Props {
	/** Sets the size of the button */
	size?: 'large' | 'medium' | 'small' | 'smaller';

	/** Sets the variant of the button */
	variant?: 'primary' | 'secondary' | 'activity' | 'experience';

	/** Sets the width of the button */
	displayWidth?: 'dynamic' | 'fixed' | 'full';

	/** Pass optional classnames for the html */
	className?: string;

	/** On click handler */
	onClick?: (e: any) => void;

	/** Should the button be disabled */
	disabled?: boolean;

	/** If button should be rendered as link or button, check Tags above. */
	as?: Tags;

	/** Is the button of type submit */
	isSubmit?: boolean;

	[htmlAttributes: string]: any;
}

const tagMapper = {
	a: 'a',
	button: 'button',
};

/** A reusable button | a component. */
const Button: React.FC<Props> = ({
	children,
	className = '',
	size = 'medium',
	variant = 'primary',
	disabled = false,
	displayWidth = 'dynamic',
	as = 'button',
	isSubmit = false,
	onClick,
	...htmlAttributes
}) => {
	let Component: any = tagMapper[as];

	return (
		<Component
			disabled={disabled}
			type={isSubmit ? 'submit' : as}
			className={clsx(
				className,
				variant !== 'activity' && 'py-4',
				'px-8',
				'text-sm',
				'inline-block',
				'outline-none',
				'rounded',
				'font-alt',
				variant === 'primary' && 'font-bold',
				disabled && ['bg-gray-300', 'text-white'],
				variant === 'primary' &&
					!disabled && [
						'bg-grey-dark',
						'text-white',
						'hover:bg-yellow-lighter',
						'hover:text-black',
						'border-2',
						'border-grey-dark',
						'focus:outline-solid-orange',
					],
				variant === 'secondary' &&
					!disabled && [
						'bg-white',
						'border-2',
						'border-gray-300',
						'text-gray-600',
						'hover:bg-white-hover',
					],
				variant === 'activity' &&
					!disabled && [
						'bg-grey-dark',
						'text-white',
						'hover:bg-yellow-lighter',
						'hover:text-black',
						'border-2',
						'border-grey-dark',
						'focus:outline-solid-orange',
					],
				variant === 'experience' &&
					!disabled && [
						'bg-yellow-lighter',
						'text-black',
						'hover:bg-yellow-lighter',
						'hover:text-black',
						'border-2',
						'focus:outline-solid-orange',
					],
				size === 'smaller' && ['h-8', 'px-2', 'py-2', 'leading-1'],
				size === 'small' && ['h-10', 'px-4', 'leading-1'],
				size === 'medium' && ['h-14', 'px-6'],
				size === 'large' && ['h-16', 'px-8'],
				displayWidth === 'full' && 'w-full',
				displayWidth === 'fixed' && 'w-full md:w-fit'
			)}
			onClick={onClick}
			{...htmlAttributes}
		>
			{children}
		</Component>
	);
};

export default Button;
