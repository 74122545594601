/**
 * SkipToMain
 */

import clsx from 'clsx';
import React from 'react';

interface Props {
	/** The text shown when :focus */
	text?: string;
	className?: string;
}

/** Main description for this component. */
const SkipToMain: React.FC<Props> = ({ text, className }) => {
	return (
		<a
			className={clsx(
				'a11yLink focus:outline-solid-orange p-2 text-sm radius-none',
				className
			)}
			href={'#main-content'}
		>
			{text}
		</a>
	);
};

export default SkipToMain;
