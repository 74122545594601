import React from 'react';
import { useSelector } from 'react-redux';
import { selectSearch } from 'store/modules/model';

import Home from 'components/Home';
import Navigation from 'components/Navigation';
import Languages from 'components/Languages';
import TopLinks from 'components/TopLinks';
import AutoComplete from 'components/AutoComplete';

interface Props {}

/** The page header in desktop mode component. */
const DesktopHeader: React.FC<Props> = () => {
	const search = useSelector(selectSearch);

	return (
		<header className="bg-dim-grey">
			<TopLinks />

			<div className="px-3 border-t border-black-25">
				<div className="flex items-center justify-between md:max-w-site md:mx-auto">
					<Home />
					<Navigation />

					<div className="flex items-center">
						<Languages />

						{search && (
							<AutoComplete
								id={search.inputId}
								action={search?.searchPageUrl}
								autocompleteApiUrl={search?.autocompleteApiUrl}
								placeholder={search?.placeholderText}
								label={search.searchLabel}
								submitText={search.searchLabel}
								name={search.queryName}
							/>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};

export default DesktopHeader;
