/**
 * ErrorPage404
 */

import React from 'react';
import { Grid, Cell } from 'components/Grid';
import Text from 'components/Text';
import { ErrorPage404Model } from 'types/pages';
import { Breadcrumbs } from 'components/Breadcrumbs';
import EpiFragments from '../../components/EpiFragments';

/** Page template for error handling. */
const ErrorPage404: React.FC<ErrorPage404Model> = ({
	heading,
	preamble,
	text,
	backgroundImage,
	_properties,
}) => {
	const imageUrl =
		backgroundImage?.src || '/assets/images/hamra-nationalpark-skog.jpeg';

	return (
		<>
			<Breadcrumbs />
			<Grid
				margin={false}
				padding={false}
				className="flex flex-grow items-start errorBackground bg-cover w-full h-site"
				style={{
					backgroundImage: `url('${imageUrl}')`,
				}}
				role="img"
				aria-label={backgroundImage?.alt}
			>
				<Cell
					span={10}
					desktop={6}
					className="xxl:w-grid-4/12 xl:w-grid-6/12 bg-white m-5 h-auto p-5 error w-5/6 imagealign"
				>
					<div className="px-10">
						<Text {..._properties?.heading} className="font-bold" as="h1">
							{heading}
						</Text>
						<p {..._properties?.preamble} className="font-benton text-sm">
							{preamble}
						</p>
						<br />
						<div className="border border-black-25 h-px"></div>
						{text && (
							<EpiFragments
								{..._properties?.text}
								fragments={text?.fragments}
							/>
						)}
					</div>
				</Cell>
			</Grid>
		</>
	);
};

export default ErrorPage404;
