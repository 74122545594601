/**
 * ReadSpeaker
 */

import clsx from 'clsx';
import Icon from 'components/Icon';
import { useScript } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNavigation } from 'store/modules/model';

interface Props {
	/** Url for readspeaker. */
	url?: string;

	/** Text to display next to icon. */
	text?: string;

	/** Title for readspeaker link. */
	title?: string;

	/** Classname with styling for readspeaker link. */
	className?: string;

	/**Class name for readspeaker container */
	containerClassName?: string;

	onRsVisibilityChanged?(isVisible: boolean): void;
}

/** Adds readspeaker functionality and visual feedback for ease of use. */
const ReadSpeaker: React.FC<Props> = ({
	url,
	text,
	title = 'Lyssna på innehållet på den här sidan.',
	className,
	containerClassName,
	onRsVisibilityChanged,
}) => {
	const [showRS, setShowRS] = useState(false);
	const navigation = useSelector(selectNavigation);

	useScript({
		url:
			'https://cdn-eu.readspeaker.com/script/9307/webReader/webReader.js?pids=wr',
		id: 'rs_req_Init',
	});

	useEffect(() => {
		function closeRS() {
			setShowRS(false);
			if (onRsVisibilityChanged) onRsVisibilityChanged(false);
		}

		if (showRS) {
			const closeBtn = document.querySelector(
				'#readspeaker_button1 > div > span > button.rsbtn_closer.rsbtn_player_item'
			);

			const mobileCloseBtn = document.querySelector('.rsmpl-closer > button');

			if (closeBtn) closeBtn.addEventListener('mouseup', closeRS);
			if (mobileCloseBtn) mobileCloseBtn.addEventListener('mouseup', closeRS);

			return () => {
				if (closeBtn) closeBtn.removeEventListener('mouseup', closeRS);
				if (mobileCloseBtn)
					mobileCloseBtn.removeEventListener('mouseup', closeRS);
			};
		}
	}, [showRS]);

	function handleShowRS() {
		setShowRS(true);
		if (onRsVisibilityChanged) onRsVisibilityChanged(true);
	}

	return (
		<div
			id="rs_button_container"
			className={clsx(
				'flex',
				className ? className : showRS ? 'justify-center' : 'justify-end',
				!className && 'px-8 w-full mt-4'
			)}
		>
			<button
				id="readspeaker_button1"
				className={clsx('rs_skip rsbtn rs_preserve', 'flex')}
			>
				<a
					rel="nofollow"
					className={clsx(
						'rsbtn_play group',
						showRS ? 'rs_visible' : 'rs_hidden'
					)}
					accessKey="L"
					title={title}
					href={url}
					onMouseUp={handleShowRS}
				>
					<div className="flex justify-around">
						<span className="rsbtn_left rsimg rspart">
							<span className="rsbtn_text flex place-items-center">
								<Icon
									size={2}
									icon="volDown"
									className={clsx(
										showRS ? 'hidden' : 'block group-hover:hidden'
									)}
								/>
								<Icon
									size={2}
									icon="volUp"
									className={clsx(
										showRS ? 'block' : 'hidden group-hover:block'
									)}
								/>
								<span>{text}</span>
							</span>
						</span>
						<button
							title={navigation?.navigationButtonLabel}
							className={clsx(
								'rsbtn_tooltoggle',
								showRS ? 'rs_visible' : 'rs_hidden'
							)}
						>
							<span
								className="rsicn rsicn-arrow-down"
								aria-hidden="true"
							></span>
						</button>
					</div>
				</a>
			</button>
		</div>
	);
};

export default ReadSpeaker;
