/**
 * SearchFieldLarge
 */

import React from 'react';
import Icon from 'components/Icon';

interface Props {
	/** Field id */
	id: string;

	/** Field label */
	label: string;

	/** Field value */
	value?: string;

	/** Field name */
	name: string;

	/** Field placeholder */
	placeholder?: string;

	/** Submit button text */
	submitText: string;

	/** On submit function */
	onSubmit?: any;

	/** Props for the label tag (coming from Downshift) */
	labelProps?: any;

	[htmlAttributes: string]: any;
}

/** Search input field component */
const SearchFieldLarge: React.FC<Props> = ({
	id,
	label,
	name,
	value,
	placeholder,
	submitText,
	labelProps,
	onSubmit,
	...htmlAttributes
}) => {
	return (
		<form
			method="POST"
			className="relative w-full mb-4 rs_skip"
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			<label htmlFor="main-search" className="sr-only" {...labelProps}>
				{label}
			</label>
			<input
				type="text"
				id="main-search"
				name="query"
				autoComplete="off"
				value={value}
				placeholder={placeholder}
				className="pl-2 py-1 pr-8 rounded-lg text-lg w-full shadow-top placeholder-primary"
				{...htmlAttributes}
			/>

			<button
				type="submit"
				aria-label={submitText}
				className="search-icon-large"
			>
				<Icon
					icon="search"
					aria-hidden="true"
					direction="down"
					className="  text-gray-600"
				/>
			</button>
		</form>
	);
};

export default SearchFieldLarge;
