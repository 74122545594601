/**
 * Puff
 */

import React from 'react';
import Text from 'components/Text';
import Button from 'components/Button';

interface Props {
	/** The list heading */
	heading: string;

	/** The list preamble */
	text?: any;

	/** Button url */
	url?: string;

	/** Button text */
	urlText: string;

	/** Epi attributes */
	_properties: any;
}

/** Main description for this component. */
const Puff: React.FC<Props> = ({
	heading,
	text,
	url,
	urlText,
	_properties,
}) => {
	return (
		<div className="bg-grey-lightest w-full py-16">
			<div className="max-w-site mx-auto flex flex-col items-center px-4">
				<div className="text-center">
					<Text as="h2" {..._properties?.heading}>
						{heading}
					</Text>
				</div>

				{text && (
					<p
						className="text-center text-base md:text-lg max-w-3xl leading-7"
						{..._properties?.text}
					>
						{text}
					</p>
				)}

				{url && (
					<Button
						className="mt-6"
						displayWidth="dynamic"
						size="medium"
						as="a"
						href={url}
						variant="primary"
					>
						{urlText}
					</Button>
				)}
			</div>
		</div>
	);
};

export default Puff;
