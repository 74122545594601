/**
 * ParkListingPage
 */

import { Breadcrumbs } from 'components/Breadcrumbs';
import ParkListing from 'components/ParkListing';
import Share from 'components/Share';
import React from 'react';
import { ParkListingPageModel } from 'types/pages';

/** Main description for this component. */
const ParkListingPage: React.FC<ParkListingPageModel> = ({ parkListing }) => {
	return (
		<>
			<Breadcrumbs />
			{parkListing && <ParkListing {...parkListing} />}

			<Share />
		</>
	);
};

export default ParkListingPage;
