/**
 * Languages
 */

import React from 'react';
import Link from '../Link';

interface Props {
	onLanguageClick?: any;
	languages: Language[];
}

interface Language {
	url: string;
	culture: string;
	text: string;
}

const getFlag = (language: Language) => {
	switch (language.culture) {
		case 'de':
			return '/assets/images/flags/lang-de-active.png';
		case 'en':
			return '/assets/images/flags/lang-uk-active.png';
		case 'smj':
			return '/assets/images/flags/lang-sme-active.png';
		case 'sv':
		default:
			return '/assets/images/flags/lang-se-active.png';
	}
};

/** Main description for this component. */
export const Languages: React.FC<Props> = ({ languages, onLanguageClick }) => {
	return (
		<ul className="flex list-none mr-4 ">
			{languages.map((language, i) => (
				<li className="mr-3" key={i}>
					<a
						onClick={onLanguageClick}
						href={language.url}
						title={language.text}
						className="focus:outline-solid-orange block"
					>
						{language.culture && (
							<img
								src={getFlag(language)}
								alt={language.text}
								aria-hidden="true"
							/>
						)}
						<span className="sr-only">{language.text}</span>
					</a>
				</li>
			))}
		</ul>
	);
};

export const LanguagesForMobile: React.FC<Props> = ({
	languages,
	onLanguageClick,
}) => {
	return (
		<div className="px-6 py-2.5">
			<ul className="pt-6 flex list-none border-t border-black-25">
				{languages.map((language, i) => (
					<li className="mr-3" key={i}>
						<a onClick={onLanguageClick} href={language.url}>
							{language.culture && (
								<img src={getFlag(language)} alt="" aria-hidden="true" />
							)}
							<span className="sr-only">{language.text}</span>
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};
