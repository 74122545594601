import { useState, useEffect } from 'react';
import { useFirstRender } from 'hooks';
import axios from 'axios';

interface UseUrlQuery {
	(initialValues: any, url?: string): [any, any, boolean, any];
}

export const useUrlQuery: UseUrlQuery = (initialValues, url) => {
	const isFirstRender = useFirstRender();
	const [values, setValues] = useState<any>(initialValues);
	const [query, setQuery] = useState<string>('');
	const [response, setResponse] = useState<any>();
	const [error, setError] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!isFirstRender && !loading) {
			let newQuery = objectToQuery(values);
			setQuery(newQuery);

			let baseUrl = url ? url : window.location.pathname;

			if (process.env.NODE_ENV === 'development') {
				baseUrl = `/api${
					window.location.pathname === '/' ? '/index' : window.location.pathname
				}.json`;
			}

			setLoading(true);
			axios({
				url: `${baseUrl}?${newQuery}`,
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'content-type': 'application/json; charset=utf-8',
				},
			})
				.then((response: any) => {
					if (response.status === 200) {
						let data = response.data;
						if (process.env.NODE_ENV === 'development') {
							data = data.content;
						}
						setResponse(data);
						setLoading(false);
					}
				})
				.catch((err) => {
					setError(err);
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	useEffect(() => {
		if (window && !isFirstRender) {
			window.history.pushState(
				{},
				document.title,
				query ? `${window.location.pathname}?${query}` : ''
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const objectToQuery = (obj: any) => {
		let str = [];
		for (let p in obj) {
			if (obj.hasOwnProperty(p)) {
				if (obj[p] !== '') {
					if (Array.isArray(obj[p])) {
						obj[p].forEach((val: string) => {
							str.push(encodeURIComponent(p) + '=' + encodeURIComponent(val));
						});
					} else {
						str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
					}
				}
			}
		}
		return str.join('&');
	};

	return [setValues, response, loading, error];
};
