/**
 * useTabFocus
 */

import { useEffect } from 'react';

export const useTabFocus = () => {
	useEffect(() => {
		const onKeyUp = (event: KeyboardEvent) => {
			const el = event.target as Element;
			const keyCode = event.keyCode ? event.keyCode : event.which;

			if (
				['a', 'button', 'input', 'textarea', 'select'].indexOf(
					el.tagName.toLowerCase()
				) !== -1 &&
				!el.hasAttribute('disabled') &&
				keyCode === 9
			) {
				el.classList.add('usability--tab-focus');
			}
		};

		const onClick = (event: MouseEvent) => {
			const el = event.target as Element;

			if (['input', 'textarea'].indexOf(el.tagName.toLowerCase()) !== -1) {
				el.classList.add('usability--tab-focus');
			}
		};

		const onBlur = (event: FocusEvent) => {
			const el = event.target as Element;

			if (el.classList && el.classList.contains('usability--tab-focus')) {
				el.classList.remove('usability--tab-focus');
			}
		};

		document.addEventListener('keyup', onKeyUp);
		document.addEventListener('click', onClick);
		document.addEventListener('blur', onBlur, true);

		return () => {
			document.removeEventListener('keyup', onKeyUp);
			document.removeEventListener('click', onClick);
			document.removeEventListener('blur', onBlur, true);
		};
	}, []);
};
