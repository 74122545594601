import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { useMediaQuery } from 'react-responsive';
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import { selectSkipToTop } from 'store/modules/model';
import clsx from 'clsx';

export interface Props {
	/** Use this to change the name of the button */
	buttonName?: string;
	footerHeight: number;
}
type Fn = () => void;

const GoTopButton: React.FC<Props> = ({ buttonName = '', footerHeight }) => {
	const skipToTop: any = useSelector(selectSkipToTop);
	buttonName = skipToTop?.text ? skipToTop?.text : 'Till toppen';
	const fh = footerHeight;
	const [showGoTop, setShowGoTop] = useState(false);
	const [endOfThePage, setEndOfThePage] = useState(true);
	const isTablet = useMediaQuery({ query: '(max-width: 767px)' });

	const handleButtonState: Fn = () => {
		// show button
		if (window.pageYOffset > 100) {
			setShowGoTop(true);
			translateYcoord();
		} else {
			setShowGoTop(false);
		}
	};

	const translateYcoord: Fn = () => {
		const totalPageHeight: number = document.body.offsetHeight;
		const scrollPoint: number =
			window.pageYOffset + window.innerHeight + footerHeight;

		if (scrollPoint >= totalPageHeight - 100) {
			setEndOfThePage(true);
		} else {
			setEndOfThePage(false);
		}
	};

	const handleScrollUp: Fn = () => {
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
	};

	const handleTranslateY = () => {
		let total = endOfThePage ? -fh : 0;
		return `translateY(${total.toString()}px)`;
	};

	useEffect(() => {
		window.addEventListener('scroll', handleButtonState);

		return () => {
			window.removeEventListener('scroll', handleButtonState);
		};
	}, []);

	return (
		<div
			className={`fixed z-40 bottom-5 right-0 block
            ${showGoTop ? 'opacity-100' : 'opacity-0 invisible'}
            opacity-effect
        `}
			style={{
				transform: handleTranslateY(),
				transition: '0.3s ease-in',
			}}
		>
			{isTablet ? (
				<Icon
					icon="chevron"
					aria-hidden="true"
					size={4}
					onClick={handleScrollUp}
					direction="down"
					className={clsx(
						'text-white',
						'cursor-pointer',
						'absolute right-4 bottom-0',
						'bg-grey-dark hover:bg-yellow-lighter hover:text-black',
						'p-2 rounded-3xl',
						'h-10 w-10'
					)}
				/>
			) : (
				<Button
					size="medium"
					children={buttonName}
					onClick={handleScrollUp}
					displayWidth="dynamic"
					className="cursor-pointer absolute right-4 bottom-0 whitespace-nowrap"
				/>
			)}
		</div>
	);
};

export default GoTopButton;
