/**
 * TopLinks
 */

import React from 'react';
import clsx from 'clsx';
import Link from '../Link';

interface Props {
	topLinks: TopLink[];
}

interface TopLink {
	href: string;
	text: string;
	title: string;
	target?: string;
}

export const TopLinks: React.FC<Props> = ({ topLinks }) => {
	return (
		<div className="md:max-w-site md:mx-auto">
			<ul className="p-2.5 flex text-sm text-white">
				{topLinks.map((link, i: number) => (
					<li key={i}>
						<Link
							href={link.href}
							className="arrow-link hover:text-yellow mr-4 text-sm"
						>
							{link.text}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export const TopLinksForMobile: React.FC<Props> = ({ topLinks }) => {
	return (
		<div className="md:max-w-header md:mx-auto bg-grey-darker">
			<ul className="px-6 py-2.5 text-sm text-white">
				{topLinks.map((link, i: number) => (
					<li
						key={i}
						className={clsx(
							'h-10',
							'flex',
							'items-center',
							i > 0 && 'border-t border-black-25'
						)}
					>
						<Link
							href={link.href}
							className="arrow-link hover:text-yellow mr-4"
						>
							{link.text}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};
