/**
 * VideoBlock
 */
import React from 'react';

interface Props {
	/** Embeded Url */
	videoUrl: string;

	/** Width */
	width?: any;

	/** Height */
	height?: any;

	/* video title to accesibility */
	videoTitle?: string;
}

/** Main description for this component. */
const VideoItem: React.FC<Props> = ({
	videoUrl,
	width,
	height,
	videoTitle = '',
}) => {
	return (
		<div className="bg-grey-lightest w-full">
			<div
				style={{ paddingBottom: height, maxWidth: width > 0 ? width : null }}
				className="relative h-0 overflow-hidden m-auto"
			>
				<iframe
					className="absolute inset-0 w-full h-full"
					src={videoUrl}
					width={width <= 0 ? '100%' : width}
					height={height}
					title={
						videoTitle === ''
							? videoTitle
							: 'Välkommen till Sveriges nationalparker'
					}
				></iframe>
			</div>
		</div>
	);
};

export default VideoItem;
