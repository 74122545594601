/**
 * ParkListing - list view
 */

import React from 'react';
import { ParkModel } from 'types/global';
import Park from './park';

type Props = {
	/** A list of parks  */
	parks: ParkModel[];
	isPhone: boolean;
	handleMouseEnter: (currentPark: ParkModel, override?: boolean) => any;
	handleMouseLeave: () => any;
	parkListHeading?: string;
};

/** Park listing listsearchview component. */
const ParkList: React.FC<Props> = ({
	parks,
	isPhone,
	handleMouseEnter,
	handleMouseLeave,
	parkListHeading,
}) => (
	<div className="bg-white pb-16 px-4 mr-0 h-full md:mr-3 md:px-0 md:absolute md:w-map-width-sm md:top-0 md:right-0 overflow-y-auto max-h-full">
		{parkListHeading && (
			<div className="border-b border-black">
				<h2 className="pt-12 pb-3 pl-5 font-semibold inline-block">
					{parkListHeading}
				</h2>
			</div>
		)}
		<ul>
			{parks.map((park: ParkModel, index: number) => (
				<li
					onMouseEnter={() => handleMouseEnter({ ...park }, true)}
					onMouseLeave={() => handleMouseLeave()}
					onFocus={() => handleMouseEnter({ ...park }, true)}
					onBlur={() => handleMouseLeave()}
					className="flex border-b border-black"
					key={`${park.imageMapCoordinates}_${index}`}
				>
					<Park isPhone={isPhone} showSmallImage {...park} />
				</li>
			))}
		</ul>
	</div>
);

export default ParkList;
