/**
 * Breadcrumbs
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
interface Props {
	/** Aria-label */
	label?: string;
}

/** Breadcrumbs component */
const Breadcrumbs: React.FC<Props> = ({ label = 'Brödsmulor', children }) => {
	const isBigTabletOrDesktop = useMediaQuery({ query: '(min-width: 960px)' });

	return (
		<>
			{isBigTabletOrDesktop && (
				<div className="bg-grey-dark">
					<nav
						aria-label={label}
						className="hidden md:block md:max-w-site md:mx-auto"
					>
						<ul className="inline-flex text-white text-sm p-2">{children}</ul>
					</nav>
				</div>
			)}
		</>
	);
};

export default Breadcrumbs;
