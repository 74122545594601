/**
 * Carousel
 */

import React from 'react';
import Slider, { Settings } from 'react-slick';
import { ICarousel, ICarouselSlide } from 'types/global';
import Image from 'components/Image/Image';
import { Link as RouterLink } from 'react-router-dom';

const CarouselNormal: React.FC<ICarousel> = ({ slides, ...htmlAttributes }) => {
	const settings: Settings = {
		dots: true,
		arrows: true,
		infinite: true,
		fade: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		className: 'slick-normal',
		appendDots: (dots: any) => {
			const activeIndex = dots.findIndex(
				(dot: any) => dot.props.className === 'slick-active'
			);

			const activeSlide = slides[activeIndex];

			return (
				<div {...htmlAttributes}>
					<div className="carousel__dots">
						{activeIndex > -1 && activeSlide && (
							<div className="carousel__infobox">
								<RouterLink to={activeSlide?.url || '#'}>
									{activeSlide.name}
								</RouterLink>
								<p>{activeSlide.image.coordinates}</p>
							</div>
						)}

						<ul className="m-0">{dots}</ul>
					</div>
				</div>
			);
		},
		customPaging: (i: number) => (
			<div key={i} className="slick__dots--custom" />
		),
	};
	return (
		<Slider {...settings}>
			{slides.map((slide: ICarouselSlide, index: number) => (
				<div key={`${slide.image.src}-${index}`}>
					<Image
						srcSet={slide.image.srcSet}
						src={slide.image.src}
						alt={slide.image.alt}
					/>
				</div>
			))}
		</Slider>
	);
};

export default CarouselNormal;
