/**
 * SearchResults
 */

import React from 'react';

interface Props {
	/** Specify number for list to start on */
	startNumber?: number;
}

/** A search result item. */
const SearchResults: React.FC<Props> = ({ startNumber, children }) => {
	return (
		<ol className="list-decimal pl-4" start={startNumber}>
			{children}
		</ol>
	);
};

export default SearchResults;
