/**
 * ParkListing
 */

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MinBreakpoint } from 'theme/media-queries';
import { ParksModel } from 'types/global';
import { useWebpSupport } from 'hooks/useWebpSupport';
import ListSearchView from './list/listsearch';
import Map from './map/map';
import EditableField from 'components/EditableField';

/** Park listing component. */
const ParkListing: React.FC<ParksModel> = ({
	searchPlaceholderText,
	parks,
	showListViewText,
	showMapViewText,
	submitText,
	parkListHeading,
	showParkList,
	heading,
	disableResponsiveSwitch = false,
}) => {
	const [showListView, toggleShowListView] = useState(false);
	const isWebpSupported = useWebpSupport();

	const isTablet = useMediaQuery({
		minWidth: MinBreakpoint.desktop,
	});

	useEffect(() => {
		if (!disableResponsiveSwitch) {
			toggleShowListView(!isTablet);
		}
	}, [isTablet]);

	const togglePermanantListView = () => toggleShowListView(!showListView);

	return (
		<div
			className={clsx(
				'relative overflow-hidden bg-grey-lightest',
				showListView ? 'px-5' : 'md:bg-transparent'
			)}
		>
			<article aria-labelledby="map-heading" className="relative">
				<div
					className={clsx(
						'text-center px-6 my-5 lg:my-0 sm:mb-5 pb-5 border-white border-b-2',
						!showListView &&
							clsx(
								'py-4',
								'md:text-left md:border-none md:absolute md:max-w-sm md:mb-0 md:pl-15% md:pt-12',
								'lg:pt-20',
								'xl:max-w-lg',
								'overflow-x-visible z-10'
							)
					)}
				>
					 <EditableField field="heading">
						<h1 id="map-heading" className="font-alt map-heading-title park-listing-title">
							{heading}
						</h1>
					 </EditableField>
					{!showParkList && (
						<button
							className="page-title arrow-small map-list-toggler arrow-link-black"
							onClick={togglePermanantListView}
						>
							{showListView ? showMapViewText : showListViewText}
						</button>
					)}
				</div>
				<div
					className={clsx('overflow-visible', !showListView && (isWebpSupported ? "map-wrapper map-wrapper-webp" : "map-wrapper map-wrapper-jpg"))}
				>
					{showListView ? (
						<ListSearchView
							parks={parks}
							searchPlaceholderText={searchPlaceholderText}
							submitText={submitText}
						/>
					) : (
						<Map
							isPhone={!isTablet}
							parks={parks}
							showParkList={showParkList}
							parkListHeading={parkListHeading}
						/>
					)}
				</div>
			</article>
		</div>
	);
};

export default ParkListing;
