/**
 * Image
 */

import clsx from 'clsx';
import Icon from 'components/Icon';
import React from 'react';
import ImageDescription from './ImageDescription';
import type { IImage } from '../../types/global';

export interface ImageProps extends IImage {
	/** The image figure caption */
	figCaption?: string;

	/** Optional classname for figure-tag. */
	figClassName?: string;

	/** Description for image, sets a dark transparent area on imagebottom. A container with position: relative should wrap Image component for this to work properly. */
	description?: string;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any

	/** Whether player symbol should show up */
	isVideo?: boolean;
}

/** The image component with or without the figure caption. */
const Image: React.FC<ImageProps> = ({
	src,
	alt = '',
	className,
	figClassName,
	figCaption,
	link,
	loading = "eager",
	decoding = "auto",
	fetchpriority = "auto",
	srcSet,
	srcSetSizes,
	description = '',
	isVideo = false,
	photographer = '',
	...htmlAttributes
}) => {

	if (!src) {
		return null;
	}


	function renderPlayBtn() {
		return (
			<Icon
				icon="play"
				className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mx-auto w-16 h-16 fill-white opacity-80"
			/>
		);
	}

	return (
		<>
			{!figCaption && (
				<div id="image-container" className="relative">
					{link ? 
						<a target={link.target} rel={link.target ? "noopener" : undefined} title={link.text} href={link.url}>
							<img
								loading={loading}
								decoding={decoding}
								src={src}
								srcSet={srcSet}
								sizes={srcSetSizes}
								alt={alt}
								{...htmlAttributes}
								className={clsx(className ? className : 'block w-full')}
							/>
						</a>:
						<img
							loading={loading}
							decoding={decoding}
							src={src}
							srcSet={srcSet}
							sizes={srcSetSizes}
							alt={alt}
							{...htmlAttributes}
							className={clsx(className ? className : 'block w-full')}
						/>
					}
					{photographer && (
						<span className="photographer">{photographer}</span>
					)}
					{isVideo && renderPlayBtn()}
				</div>
			)}
			{figCaption && (
				<figure
					className={clsx('mb-4 relative', figClassName)}
					id="image-container"
				>
					{link ? 
						<a target={link.target} rel={link.target ? "noopener" : undefined} title={link.text} href={link.url}>
							<img
								loading={loading}
								decoding={decoding}
								src={src}
								srcSet={srcSet}
								sizes={srcSetSizes}
								{...htmlAttributes}
								alt={alt}
								className={clsx(className ? className : 'block w-full mb-0')}
							/>
						</a>:
						<img
							loading={loading}
							decoding={decoding}
							src={src}
							srcSet={srcSet}
							sizes={srcSetSizes}
							{...htmlAttributes}
							alt={alt}
							className={clsx(className ? className : 'block w-full mb-0')}
						/>
					}
					{photographer && (
						<span className="photographer">{photographer}</span>
					)}
					{isVideo && renderPlayBtn()}
					<figcaption className="py-2 bg-gray-100 text-black w-full text-sm">
						{figCaption}
					</figcaption>
				</figure>
			)}

			{description && <ImageDescription description={description} />}
		</>
	);
};

export default Image;
