/**
 * Result
 */

import React from 'react';
interface Props {
	/** Result heading */
	heading: string;

	/** Result excerpt */
	excerpt?: string;

	/** Link url */
	url: string;

	/** Result item id */
	id: string;
}

/** A search result item. */
const Result: React.FC<Props> = ({ heading, excerpt, url, id }) => {
	return (
		<li>
			<article aria-labelledby={`heading-${id}`}>
				<a
					href={url}
					className="block ml-2 border-solid border-b border-grey-light pb-6 pt-4"
				>
					<h2
						id={`heading-${id}`}
						className="underline font-semibold mb-3 text-base hover:no-underline"
						dangerouslySetInnerHTML={{ __html: heading }}
					/>

					{excerpt && (
						<p
							className="text-base"
							dangerouslySetInnerHTML={{ __html: excerpt }}
						/>
					)}
				</a>
			</article>
		</li>
	);
};

export default Result;
