import React from 'react';

import { checkDependency } from 'components/EpiForms/EpiForms.dependencies';
import { checkFieldActions } from 'components/EpiForms/EpiForms.actions';
import {
	Field,
	TextArea,
	Select,
	Multiselect,
	RadioButton,
	Checkbox,
	Fieldset,
	Button,
	Label,
	ErrorSpan,
} from 'components/Form';

interface Field {
	id: string;
	label: string;
	required?: boolean;
	[htmlAttributes: string]: any;
}

interface RenderFunction {
	(field: Field): JSX.Element | null;
}

export const useFormRenderer = (fields: any, state: any, dependencies: any) => {
	const { invalidFields, validationMessages, isLoading, values } = state;
	let fieldActionExists = false;

	const renderFormElement = (fieldName: string) => {
		const field = fields[fieldName];
		const { name, text } = fields[fieldName];
		let { type } = fields[fieldName];

		if (type === null) {
			type = 'text';
		}

		const renderAsFieldset =
			(type === 'radio' || type === 'checkbox') && field.options.length > 1;
		const singleCheckboxOrRadio =
			(type === 'radio' || type === 'checkbox') && field.options.length === 1;

		const fieldHasDependency =
			dependencies &&
			dependencies.filter((field: any) => field.fieldName === name).length > 0;

		if (fieldHasDependency) {
			const fieldShouldBeRendered = checkDependency(name, dependencies, values);

			if (!fieldShouldBeRendered) {
				return;
			}
		}

		if (renderAsFieldset) {
			return <div key={name}>{renderFieldset(field)}</div>;
		} else if (singleCheckboxOrRadio) {
			return (
				<div key={name}>
					{renderSingleInput(field)}
					{renderFieldError(field.name)}
				</div>
			);
		} else if (type === 'ParagraphText') {
			return <div key={name} dangerouslySetInnerHTML={{ __html: text }} />;
		} else {
			let typeValue = '';
			if (field.type === 'reset' || field.type === 'submit') {
				typeValue = field.type === 'reset' ? 'float-left' : 'float-right';
			}
			const isBtnTypeResetOrSubmit =
				field.type === 'reset' || field.type === 'submit';
			return (
				<>
					{isBtnTypeResetOrSubmit ? (
						<>
							{field.type === 'reset' && (
								<div className="mt-16 pt-6 border-t-4 border-yellow " />
							)}
							<div key={name} id={`form${name}`} className={typeValue}>
								{field.type !== 'multiselect' && renderLabel(field)}
								{renderField(field)}
								{renderFieldError(field.name)}
							</div>
						</>
					) : (
						<div key={name} id={`form${name}`}>
							{field.type !== 'multiselect' && renderLabel(field)}
							{renderField(field)}
							{renderFieldError(field.name)}
						</div>
					)}
				</>
			);
		}
	};

	const renderLabel: RenderFunction = ({
		id,
		label,
		required,
		type,
		helpText,
	}) => {
		if (!label || type === 'submit' || type === 'reset') {
			return null;
		}

		return (
			<Label htmlFor={id} required={required} helpText={helpText}>
				{label}
			</Label>
		);
	};

	const renderFieldError = (id: string) => {
		const validationMessage =
			validationMessages[id] && validationMessages[id].message;
		const isInvalid = invalidFields.indexOf(id) !== -1;

		return (
			<ErrorSpan fieldId={id} invalid={isInvalid}>
				{validationMessage}
			</ErrorSpan>
		);
	};

	const renderField: RenderFunction = ({
		name,
		type,
		required,
		pattern,
		placeholder,
		disabled,
		description,
		min,
		max,
		label,
		failedMessage,
		patternMessage,
		options,
		fieldActions,
	}) => {
		let buttonText = label || type;

		switch (type) {
			case 'submit':
				if (type === 'submit' && isLoading) {
					buttonText = 'Loading...';
				}
				return (
					<Button
						type={type}
						name={name}
						disabled={isLoading}
						id={name}
						className="bg-yellow rounded-lg p-4 leading-5 text-lg"
					>
						{buttonText}
					</Button>
				);
			case 'reset':
				return (
					<Button
						type={type}
						name={name}
						disabled={isLoading}
						id={name}
						className="border-yellow border-solid rounded-lg border p-4 leading-5 text-lg reset-btn "
					>
						{buttonText}
					</Button>
				);
			case 'textarea':
				return (
					<TextArea
						id={name}
						name={name}
						description={description}
						required={required}
						placeholder={placeholder}
						disabled={disabled}
						label={label}
						validationMessage={failedMessage}
						className="form-text-area"
					/>
				);
			case 'select':
				return (
					<Select
						id={name}
						name={name}
						label={label}
						description={description}
						required={required}
						options={options}
						validationMessage={failedMessage}
						className="form-text-area border-black"
					/>
				);
			case 'multiselect':
				return (
					<Multiselect
						id={name}
						label={label}
						description={description}
						required={required}
						options={options}
						validationMessage={failedMessage}
						isInvalid={invalidFields.indexOf(name) !== -1}
					/>
				);
			case 'text':
			default:
				let actions: any = {};

				if (fieldActions) {
					actions = checkFieldActions(fieldActions, values);
					fieldActionExists = true;
				}

				return (
					<Field
						type={type}
						id={name}
						name={name}
						pattern={pattern}
						required={(actions && actions.required) || required}
						placeholder={placeholder}
						description={description}
						label={label}
						min={min}
						max={max}
						disabled={disabled}
						validationMessage={failedMessage}
						patternMessage={patternMessage}
						className="form-text-area"
					/>
				);
		}
	};

	const renderFieldset: RenderFunction = ({
		name,
		type,
		options,
		label,
		required,
		description,
		validationMessage,
		helpText,
	}) => {
		const isInvalid = invalidFields.indexOf(name) !== -1;
		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;

		return (
			<div>
				<Fieldset
					id={name}
					description={description}
					required={required}
					label={label}
					isInvalid={isInvalid}
					validationMessage={validationMessage}
					className="text-lg mt-16"
					helpText={helpText}
				>
					{options.map((opt: any, index: number) => {
						return (
							<label
								htmlFor={`${name}-${index}`}
								key={`${name}-${index}`}
								className="py-3 "
							>
								<FieldComponent
									id={name}
									index={index}
									name={name}
									value={opt.value}
									label={opt.caption}
									className="mr-2"
								/>
								<span>{opt.caption}</span>
							</label>
						);
					})}
				</Fieldset>
			</div>
		);
	};

	const renderSingleInput: RenderFunction = ({
		name,
		type,
		options,
		required,
		disabled,
		description,
		validationMessage,
	}) => {
		const fieldLabel = options[0].caption;
		const fieldValue = options[0].value;

		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;

		return (
			<label htmlFor={name} title={description}>
				<FieldComponent
					id={name}
					name={name}
					value={fieldValue}
					required={required}
					disabled={disabled}
					single={true}
					label={fieldLabel}
					validationMessage={validationMessage}
				/>
				<span>
					{fieldLabel}
					{required && <abbr title="Obligatoriskt">*</abbr>}
				</span>
			</label>
		);
	};

	return [renderFormElement, fieldActionExists];
};
