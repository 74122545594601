import React from 'react';
import { useSelector } from 'react-redux';
import { MobileHeader as Component } from './MobileHeader';
import { selectNavigation } from 'store/modules/model';

interface Props {}

const MobileHeader: React.FC<Props> = () => {
	const navigation: any = useSelector(selectNavigation);

	return <Component menuButtonLabel={navigation?.navigationButtonLabel} />;
};

export default MobileHeader;
