/**
 * ActivityList
 */

import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';
import ActivityStandardBlock from 'components/ActivityStandardBlock/ActivityStandardBlock';
import { Grid } from 'components/Grid';
import Link from 'components/Link';
import { IImage } from 'types/global';

interface ActivityGroupModel {
	/** Description of this property */
	groups?: ActivitiesPuffModel[];
	showMoreText?: string | null;
}

export interface ActivitiesPuffModel {
	modelType?: 'ActivitiesPuffModel';
	activities?: Activity[];
	name?: string;
	url?: string;
}

export interface Activity {
	image: IImage;
	url: string;
	name: string;
	when?: string;
	alt?: string;
}

/** Main description for this component. */
const ActivityList: React.FC<ActivityGroupModel> = ({
	groups = [],
	showMoreText = null,
}) => {
	return (
		<>
			{groups.map((group: ActivitiesPuffModel, i: number) => (
				<section className="w-full" key={i.toString()}>
					<div
						className={clsx('mt-4 w-full', [
							showMoreText && 'border-b-2 border-white',
						])}
					>
						<div className="flex justify-between pr-3 mx-3 lg:w-880">
							{group.name && (
								<Text
									as={'h2'}
									styleAs="span"
									className="text-xl mx-2 lg:mx-0"
									text={group.name}
								/>
							)}
							{showMoreText && (
								<Link className="text-sm leading-7" to={group.url}>
									<span className="triangle"></span>
									{showMoreText}
								</Link>
							)}
						</div>
					</div>
					<Grid
						padding={false}
						margin={false}
						className="pt-4 w-full puff-in-grid"
					>
						{group.activities?.map((activity: Activity, i: number) => (
							<ActivityStandardBlock
								key={i.toString()}
								TitleLevel="h2"
								heading={activity.name}
								{...activity}
							/>
						))}
					</Grid>
				</section>
			))}
		</>
	);
};

export default ActivityList;
