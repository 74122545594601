/**
 * SearchField
 */

import React from 'react';
import Icon from 'components/Icon';

interface Props {
	/** Field id */
	id: string;

	/** Field label */
	label: string;

	/** Field name */
	name: string;

	/** Field placeholder */
	placeholder?: string;

	/** Submit button text */
	submitText: string;

	[htmlAttributes: string]: any;
}

/** Search input field component */
const SearchField: React.FC<Props> = ({
	id,
	label,
	name,
	placeholder,
	submitText,
	...htmlAttributes
}) => {
	return (
		<div className="relative w-full">
			<label htmlFor={id} className="sr-only">
				{label}
			</label>
			<input
				type="text"
				id={id}
				name={name}
				autoComplete="off"
				placeholder={placeholder}
				className="pl-2 py-0.5 pr-8 rounded-sm text-base w-full placeholder-primary"
				{...htmlAttributes}
			/>
			<button type="submit" className="search-icon">
				<span className="sr-only">{submitText}</span>
				<Icon
					icon="search"
					aria-hidden="true"
					direction="down"
					className="text-gray-600"
				/>
			</button>
		</div>
	);
};

export default SearchField;
