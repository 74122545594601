import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model } from 'types/epi';
import { ContentTypes } from 'types/pages';
import { RootState, AppThunk } from 'store';
import { getEpiPage, getPageContentWithContentLink } from 'api/epiApi';

interface ModelState {
	model: Model;
	error: string | null;
	loading: boolean;
}

const initialState: ModelState = {
	error: null,
	loading: false,
	model: {
		content: undefined,
	},
};

const slice = createSlice({
	name: 'model',
	initialState,
	reducers: {
		getModelStart: (state) => {
			state.loading = true;
		},
		getModelSuccess: (state, action: PayloadAction<Model>) => {
			state.model = action.payload;
			state.error = null;
			state.loading = false;
		},
		getModelFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateContent: (state, action: PayloadAction<ContentTypes>) => {
			state.model.content = action.payload;
		},
	},
});

export const fetchPage = (
	routeApiUrl: string,
	route: string
): AppThunk => async (dispatch) => {
	let data;
	dispatch(getModelStart());

	try {
		data = await getEpiPage(routeApiUrl, route);
	} catch (err) {
		dispatch(getModelFailed(err.toString()));
		return;
	}

	dispatch(getModelSuccess(data));
};

export const fetchPageWithContentLink = (
	contentLink: string
): AppThunk => async (dispatch) => {
	let data;
	try {
		data = await getPageContentWithContentLink(contentLink);
	} catch (error) {
		console.log(error);
		return;
	}
	if (data.status === 200) {
		dispatch(updateContent(data.result));
	}
};

export const selectModel = (state: RootState) => state.model;
export const selectContent = (state: RootState) => state.model.model.content;
export const selectLocalization = (state: RootState) =>
	state.model.model.languageSelector;
export const selectResponsible = (state: RootState) =>
	state.model.model.responsible;
export const selectFooterLinks = (state: RootState) =>
	state.model.model.footerLinks;
export const selectHeader = (state: RootState) => state.model.model.header;
export const selectNavigation = (state: RootState) =>
	state.model.model.navigation;
export const selectLanguageSelector = (state: RootState) =>
	state.model.model.languageSelector;
export const selectBreadcrumbs = (state: RootState) =>
	state.model.model.breadcrumbs;
export const selectShare = (state: RootState) => state.model.model.share;
export const selectSearch = (state: RootState) => state.model.model.search;
export const selectMetadata = (state: RootState) => state.model.model.metadata;
export const selectTranslations = (state: RootState) =>
	state.model.model.translations;
export const selectReadSpeaker = (state: RootState) =>
	state.model.model.readSpeaker;
export const selectHome = (state: RootState) => state.model.model.home;
export const selectLanguages = (state: RootState) =>
	state.model.model.languageSelector;
export const selectSkipToMain = (state: RootState) =>
	state.model.model.skipToMain;
export const selectSkipToTop = (state: RootState) =>
	state.model.model.skipToTop;
export const selectTopLinks = (state: RootState) => state.model.model.topLinks;
export const selectMenu = (state: RootState) => state.model.model.menu;
export const {
	getModelStart,
	getModelSuccess,
	getModelFailed,
	updateContent,
} = slice.actions;

export default slice.reducer;
