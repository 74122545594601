/**
 * ResetPasswordPage
 */

import axios from 'axios';
import { Breadcrumbs } from 'components/Breadcrumbs';
import Button from 'components/Button';
import Notification from 'components/Notification';
import { NotificationType } from 'components/Notification/Notification';
import React, { useEffect, useMemo, useState } from 'react';
import { ResetPasswordPageModel } from 'types/pages';

const PasswordResetResult = {
	SENT: 'passwordrequestsent',
	SENDFAIL: 'passwordrequestsentfail',
	SUCCESS: 'passwordresetsuccess',
	SENDING: 'passwordrequestsending',
	NSTART: 'requestnotstarted',
};

/** Main description for this component. */
const ResetPasswordPage: React.FC<ResetPasswordPageModel> = (props) => {
	const [requestState, setRequestState] = useState(PasswordResetResult.NSTART);
	const [resultMessage, setResultMessage] = useState<String>(
		props.passwordResetSuccessMessage
	);

	async function submitEmailAddress(event: any) {
		event.preventDefault();
		setRequestState(PasswordResetResult.SENDING);

		const email = event.currentTarget.elements.email.value;

		if (email == '' || email == null) {
			setRequestState(PasswordResetResult.SENDFAIL);
			return;
		}

		try {
			const res = await axios.post(`${props.url}${email}`);
			const data = res.data;

			status(data);
		} catch (e) {
			setRequestState(PasswordResetResult.SENDFAIL);
			setResultMessage(props.passwordRequestFailMessage);
		}
	}

	const notificationTheme = useMemo((): NotificationType => {
		switch (requestState) {
			case PasswordResetResult.SENDFAIL:
				return 'error';

			case PasswordResetResult.SUCCESS:
			case PasswordResetResult.SENT:
				return 'success';

			case PasswordResetResult.SENDING:
			default:
				return 'info';
		}
	}, [requestState]);

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const statusParam = urlParams.get('status');
		if (statusParam != null) {
			status(statusParam);
		}
	}, []);

	return (
		<div style={{ minHeight: '70vh' }} className="bg-grey-lightest">
			<Breadcrumbs />
			<section className="max-w-5xl mx-auto">
				<h1 className="text-h3 md:text-h1 my-4">{props.heading}</h1>
				<div className="w-full border-t-4 border-yellow bg-white py-4 px-6">
					{requestState !== PasswordResetResult.NSTART && (
						<Notification
							type={notificationTheme}
							isLoading={requestState === PasswordResetResult.SENDING}
							hidden={requestState === PasswordResetResult.NSTART}
						>
							{resultMessage}
						</Notification>
					)}
					<form
						className="flex flex-col gap-4 my-4"
						onSubmit={submitEmailAddress}
					>
						<label htmlFor="resetpasswordfield">
							{props.resetPasswordLabelText}
						</label>
						<div className="max-w-sm flex gap-4">
							<input
								name="email"
								id="resetpasswordfield"
								type="text"
								className="rounded-sm text-base justify-start placeholder-primary border border-black flex-1"
							/>
							<Button
								size="small"
								isSubmit
								disabled={requestState == PasswordResetResult.SENDING}
							>
								{props.resetPasswordButtonText}
							</Button>
						</div>
					</form>
				</div>
			</section>
		</div>
	);

	function status(data: any) {
		switch (data) {
			case PasswordResetResult.SENT:
				setRequestState(PasswordResetResult.SENT);
				setResultMessage(props.passwordRequestSentMessage);
				break;

			case PasswordResetResult.SUCCESS:
				setRequestState(PasswordResetResult.SUCCESS);
				setResultMessage(props.passwordResetSuccessMessage);
				break;

			case PasswordResetResult.SENDFAIL:
			default:
				setRequestState(PasswordResetResult.SENDFAIL);
				setResultMessage(props.passwordRequestFailMessage);
				break;
		}
	}
};

export default ResetPasswordPage;
