import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguages } from 'store/modules/model';

import { Languages as Component, LanguagesForMobile } from './Languages';

interface Props {
	forMobile?: boolean;
	onLanguageClick?: any;
}

const Languages: React.FC<Props> = ({ forMobile, onLanguageClick }) => {
	const languages: any = useSelector(selectLanguages);

	if (forMobile) {
		return (
			<LanguagesForMobile {...languages} onLanguageClick={onLanguageClick} />
		);
	}

	return <Component {...languages} onLanguageClick={onLanguageClick} />;
};

export default Languages;
