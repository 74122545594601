import React from 'react';
import { useSelector } from 'react-redux';
import { ShareModel } from './types';
import Component from './ShareArea';
import { selectShare } from '../../store/modules/model';

const Share = () => {
	const model: ShareModel = useSelector(selectShare);

	return <>{model && <Component {...model} />}</>;
};

export default Share;
