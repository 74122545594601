/**
 * PagerBtn
 */

import React from 'react';

interface Props {
	/** Link href */
	href: string;

	/** Button key */
	buttonKey: number | string;

	/** Aria label for button */
	label?: string;

	onClick: any;
}

const PagerBtn: React.FC<Props> = ({
	href,
	buttonKey,
	label,
	onClick,
	children,
}) => (
	<li key={buttonKey}>
		<a
			className="py-2 px-4 underline hover:no-underline flex items-center justify-center"
			href={href}
			aria-label={label}
			onClick={(e: any) => {
				e.preventDefault();
				onClick();
			}}
		>
			{children}
		</a>
	</li>
);

export default PagerBtn;
