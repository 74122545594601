/**
 * PreviewBlockPage
 */

import React from 'react';
import { PreviewBlockPageModel } from 'types/pages';
import Layout from 'layouts';

import { Grid, Cell } from 'components/Grid';
import EpiContentArea from 'components/EpiContentArea';

/** Page template to enable OPE on blocks */
const PreviewBlockPage: React.FC<PreviewBlockPageModel> = ({ contentArea }) => (
	<Layout>
		<Grid>
			<Cell span={0} tablet={2} desktop={2}>
				&nbsp;
			</Cell>
			<Cell span={7}>
				<EpiContentArea content={contentArea} />
			</Cell>
		</Grid>
	</Layout>
);

export default PreviewBlockPage;
