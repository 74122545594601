/**
 * ArticlePage
 */

import clsx from 'clsx';
import ActivityInfo from 'components/ActivityInfo';
import { Breadcrumbs } from 'components/Breadcrumbs';
import EditableField from 'components/EditableField';
import EpiFragments from 'components/EpiFragments';
import { Cell, Grid } from 'components/Grid';
import Image from 'components/Image';
import Menu from 'components/Menu';
import ReadSpeaker from 'components/ReadSpeaker';
import Share from 'components/Share';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { selectMenu, selectReadSpeaker } from 'store/modules/model';
import { MinBreakpoint } from 'theme/media-queries';
import { ArticlePageModel } from 'types/pages';

/** Page template for article page. */
const ArticlePage: React.FC<ArticlePageModel> = ({
	heading,
	preamble,
	aboutText,
	text,
	topImage,
	activityInfo = null,
	parkName = '',
	_properties = {},
}) => {
	const readSpeaker = useSelector(selectReadSpeaker);
	const menu = useSelector(selectMenu);
	const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
	const isBigTablet = useMediaQuery({ query: '(max-width: 960px)' });
	const isDesktop = useMediaQuery({
		query: `(min-width: ${MinBreakpoint.desktop})`,
	});

	const isFormContainer: boolean = text?.fragments.some((item: any) => {
		return item.modelType === 'FormContainer';
	});

	const [isRsVisible, setIsRsVisible] = useState(false);

	return (
		<>
			<Breadcrumbs />
			{isBigTablet && (
				<h1 className="border-b border-stone-light mb-4 pt-6 pb-2 mx-3 text-xl">
					{parkName}
				</h1>
			)}
			<div className="w-full flex justify-center bg-grey-lighter">
				<Grid
					maxWidth={true}
					padding={false}
					margin={false}
					className={`bg-grey-lighter xl:max-w-screen-xl justify-center ${
						isFormContainer ? 'xl:w-1280' : ''
					}`}
				>
					{menu && (
						<Cell
							margin={false}
							className="hidden md:block bg-white py-4 px-3"
							span={3}
						>
							<Menu {...menu} />
						</Cell>
					)}
					<Cell
						className="ml-0 bg-white px-0 md:px-2 py-4 breaking-words md:break-normal"
						span={12}
						tablet={9}
						margin={false}
					>
						<EditableField field="mainImage">
							{topImage && (
								<div className="mb-2 relative" {..._properties?.mainImage}>
									<Image {...topImage} />
								</div>
							)}
						</EditableField>
						<Grid
							margin={false}
							padding={false}
							className={clsx(
								'p-4 md:p-0 flex-col md:flex-row rssnp-readarea',
								isRsVisible && 'relative'
							)}
						>
							<Cell
								margin={false}
								span={12}
								tablet={8}
								className={clsx(isRsVisible && !isDesktop && 'mt-8')}
							>
								{readSpeaker && !isTablet && (
									<ReadSpeaker
										url={readSpeaker?.url}
										text={readSpeaker?.text}
										className="md:hidden flex justify-end"
									/>
								)}
								<EditableField field='heading'>
									<Text
										as="h1"
										styleAs="h1hero"
										margin={false}
										className={'font-bold my-5 breaking-words md:break-normal'}
									>
										{heading}
									</Text>
								</EditableField>
							
								<div className="mb-5">
									<EditableField field='preamble'>
										<p className={'leading-normal text-sm font-benton font-bold'}>
											{preamble}
										</p>
									</EditableField>
								</div>
								{aboutText && (
									<p
										className={`${
											isFormContainer
												? 'text-3xl leading-9 pb-6 border-b-4 border-yellow '
												: 'pb-4'
										}`}
									>
										{aboutText}
									</p>
								)}
								<div className="imagealign">
									<EpiFragments
										{..._properties?.text}
										fragments={text?.fragments}
									/>
								</div>
							</Cell>
							<Cell className="md:pl-2" span={12} tablet={4}>
								{readSpeaker && isTablet && (
									<ReadSpeaker
										url={readSpeaker?.url}
										text={readSpeaker?.text}
										onRsVisibilityChanged={(isVisible) =>
											setIsRsVisible(isVisible)
										}
										className={clsx(
											'flex',
											isRsVisible
												? 'justify-start absolute left-0 top-0'
												: 'justify-center'
										)}
									/>
								)}
								{activityInfo && (
									<ActivityInfo
										{...activityInfo}
										onPageEditHtmlAttributes={_properties}
									/>
								)}
							</Cell>
						</Grid>
					</Cell>
				</Grid>
			</div>

			<Share />
		</>
	);
};

export default ArticlePage;
