/**
 * ShareArea
 */

import React from 'react';
import clsx from 'clsx';
import Text from 'components/Text';
import {
	ShareModel,
	ShareWithTwitter,
	ShareWithFacebook,
	ShareWithGooglePlus,
	ShareWithMail,
} from './types';

const ShareWithTwitterComponent: React.FC<ShareWithTwitter> = ({
	name,
	text,
	type,
	url,
}) => {
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`https://twitter.com/home?status=${url}`}
			title={text}
			className={clsx(
				`share-${type}-link`,
				'inline-flex items-center justify-center'
			)}
		>
			<img
				alt={name}
				aria-hidden="true"
				src={`/assets/images/share-twitter-white.png`}
			/>
		</a>
	);
};

const ShareWithFacebookComponent: React.FC<ShareWithFacebook> = ({
	name,
	text,
	type,
	url,
	campaignName,
	campaignSource,
	campaignMedium,
}) => {
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
			title={text}
			className={clsx(
				`share-${type}-link`,
				'inline-flex items-center justify-center'
			)}
		>
			<img
				alt={name}
				aria-hidden="true"
				src={`/assets/images/share-facebook.png`}
			/>
		</a>
	);
};

const ShareWithGooglePlusComponent: React.FC<ShareWithGooglePlus> = ({
	name,
	text,
	type,
	url,
}) => {
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`https://plus.google.com/share?url=${url}`}
			title={text}
			className={clsx(
				`share-${type}-link`,
				'inline-flex items-center justify-center'
			)}
		>
			<img
				alt={name}
				aria-hidden="true"
				src={`/assets/images/share-google.png`}
			/>
		</a>
	);
};

const ShareWithMailComponent: React.FC<ShareWithMail> = ({
	name,
	text,
	type,
	url,
	subject,
}) => {
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`mailto:?subject=${subject}&body=${url}`}
			title={text}
			className={clsx(
				`share-${type}-link`,
				'inline-flex items-center justify-center bg-grey-light'
			)}
		>
			<img
				alt={name}
				aria-hidden="true"
				className="bg-grey-light p-3"
				src={`/assets/images/share-email.png`}
			/>
		</a>
	);
};

const Components: any = {
	twitter: ShareWithTwitterComponent,
	facebook: ShareWithFacebookComponent,
	google: ShareWithGooglePlusComponent,
	mail: ShareWithMailComponent,
};

/** ShareArea component. */
const ShareArea: React.FC<ShareModel> = ({ heading, text, url, shares }) => {
	return (
		<div className="px-6 md:px-0 py-6 bg-white border-t border-grey-e1 ">
			<div className="text-center max-w-2xl mx-auto">
				<Text as="h2" className="text-h3 leading-7 mb-3 font-bold">
					{heading}
				</Text>

				<Text as="preamble" className="mb-1 leading-5">
					{text}
				</Text>

				<ul className="share-list flex flex-wrap justify-center">
					{shares.map((share) => {
						var Component = Components[share.type];
						return (
							<li className="m-1 md:mx-2 md:my-0">
								<Component {...share} url={url} />
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default ShareArea;
