/**
 * ParkListing - map
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ParkModel } from 'types/global';
import Area from 'components/ParkListing/map/area';
import Text from 'components/Text';
import clsx from 'clsx';
import Park from './park';
import ParkList from './parklist';
import Image from 'components/Image';

type Props = {
	/** A list of parks  */
	parks: ParkModel[];

	showParkList: boolean;

	parkListHeading?: string;

	isPhone: boolean;
};

/** Park listing map component. */
const Map: React.FC<Props> = ({
	parks,
	showParkList,
	parkListHeading,
	isPhone,
}) => {
	const [showOverlay, setShowOverlay] = useState(false);

	/** Position in the map area, left/right */
	const [overlayPosition, setOverlayPosition] = useState<any>(null);
	const [overlayClassName, setOverlayClassName] = useState<string>('');
	const [currentPark, setCurrentPark] = useState<any | ParkModel>(null);
	const [canHoverOverlay, setCanHoverOverlay] = useState(true);

	const overlayRef = useRef<HTMLDivElement>(null);

	const handleResetCurrentPark = () => {
		setShowOverlay(false);
		setOverlayPosition(null);
		setCurrentPark(null);
		setCanHoverOverlay(true);
	};

	const handleMouseEnter = useCallback(
		(park: ParkModel, override = false) => {
	
			const validPark = park != null;
			const canShowOverlay =
				!showOverlay ||
				override ||
				park.imageMapCoordinates !== currentPark.imageMapCoordinates;

			if (validPark) {
				setShowOverlay(true);
				setCurrentPark(park);
			}
		},
		[showOverlay, currentPark]
	);

	const handleClickArea = (e: any, park: ParkModel) => {
		e.preventDefault();

		// TODO: Click reopens the div even if same park
		setCurrentPark(park);
		setOverlayPosition(null);
		setCanHoverOverlay(false);
		setShowOverlay(true);
	};

	const handleCloseArea = () => handleResetCurrentPark();

	const handleMouseLeave = () => {
		if (canHoverOverlay) {
			handleResetCurrentPark();
		}
	};

	useEffect(() => { // events for escape and click outside of overlay
		const clickEscape = (e: KeyboardEvent) => {
			if(e.key === "Escape"){ // Esc button
				handleResetCurrentPark();
			} 
		}

		const clickOutsideOverlay = (e: MouseEvent) => {
			let element = e.target as Element;
			if(element.tagName === "AREA") return; // If area is click dont close the overlay;

			if(!element.classList.contains("map-overlay") || !element.closest(".map-overlay")){
				handleResetCurrentPark();
			}
		}

		document.addEventListener("keydown", clickEscape);
		document.addEventListener("click", clickOutsideOverlay);

		return () => {
			document.removeEventListener("keydown", clickEscape);
			document.removeEventListener("click", clickOutsideOverlay);
		}
	},[]);

	useEffect(() => {
		if (currentPark && currentPark.imageMapCoordinates) {
			const overlayHeight = overlayRef.current?.scrollHeight || 0;
			const cor = currentPark.imageMapCoordinates
				.split(',')
				.map((a: string) => a.trim());

			let x = parseInt(cor[0]) - 33;
			let y = parseInt(cor[1]) - overlayHeight - 20;

			let cn = overlayClassName;

			if (!canHoverOverlay) {
				if (parseInt(cor[1]) - overlayHeight < 10) {
					cn = 'top-arrow';
					y += overlayHeight + 38;
				} else {
					cn = '';
				}
			} else {
				if (y < -10) {
					cn = 'top-arrow';
					y += overlayHeight + 38;
				} else {
					cn = '';
				}
			}

			setOverlayPosition({
				left: `${x}px`,
				top: `${y}px`,
			});

			setOverlayClassName(cn);
		}
	}, [currentPark, canHoverOverlay, overlayClassName]);

	return (
		<>
			<div
				className={clsx(
					'map-inner',
					'mx-auto',
					'max-w-full',
					'w-map-width-lg',
					showParkList && isPhone && 'hidden'
				)}
			>
				<div className="relative">
					<Image 
					srcSet='/assets/images/karta.webp, /assets/images/karta.png'
					src="/assets/images/karta.png"
					alt="Karta över Sverige och dess nationalparker"
					aria-hidden="true"
					useMap="#imagemap"
					/>
					
					<map name="imagemap" id="imagemap">
						{parks.map((park: ParkModel, index: number) => (
							<Area
								key={index}
								{...park}
								handleMouseEnter={handleMouseEnter}
								handleMouseLeave={handleMouseLeave}
								handleClickArea={handleClickArea}
							/>
						))}
					</map>

					{currentPark && (
						<div
							ref={overlayRef}
							className={clsx(
								'map-overlay',
								'bg-white',
								'shadow-md',
								'absolute',
								'z-10',
								isPhone ? 'w-32' : 'w-64',
								showOverlay ? 'block' : 'hidden',
								overlayClassName
							)}
							{...(overlayPosition && { style: overlayPosition })}
						>
							<div className="relative">
								<Park
									{...currentPark}
									canHoverOverlay={canHoverOverlay}
									isPhone={isPhone}
									border
								>
									<button
										className={clsx(
											'absolute',
											'inset-1',
											'left-auto',
											'bottom-auto',
											!canHoverOverlay ? 'block' : 'hidden'
										)}
										onClick={handleCloseArea}
									>
										<Text as="screenReader" aria-live="assertive">
											Stäng ruta
										</Text>
										<img
											src="/assets/images/close-circle.png"
											alt=""
											aria-hidden={true}
										/>
									</button>
								</Park>
							</div>
						</div>
					)}
				</div>
			</div>

			{showParkList && (
				<ParkList
					handleMouseEnter={handleMouseEnter}
					handleMouseLeave={handleMouseLeave}
					isPhone={isPhone}
					parks={parks}
					parkListHeading={parkListHeading}
				/>
			)}
		</>
	);
};

export default Map;
