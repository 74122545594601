/**
 * ResponsiveIframe
 */

import React from 'react';
import clsx from 'clsx';

interface Props {
	/** Iframe src */
	src: string;

	/** The aspect ratio of the video:
	 *
	 * - 16:9 - most common format, used by YouTube videos.
	 * - 4:3 - used mostly by older videos.
	 */
	aspectRatio?: '16:9' | '4:3';

	/** Aria-label for the component */
	'aria-label': string;
}

const ResponsiveIframe: React.FC<Props> = ({
	src,
	'aria-label': ariaLabel,
	aspectRatio = '16:9',
}) => {
	return (
		<div
			data-cid="ResponsiveIframe"
			className={clsx(
				'relative overflow-hidden',
				aspectRatio === '16:9' ? 'pt-56%' : 'pt-75%'
			)}
		>
			<iframe
				src={src}
				aria-label={ariaLabel}
				className="absolute inset-0 w-full h-full"
			/>
		</div>
	);
};

export default ResponsiveIframe;
