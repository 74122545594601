/**
 * PageFooterLink
 */

import Link from 'components/Link';
import React from 'react';

interface Props {
	/** Link url */
	href?: string;
}

/** Link inside of footer. */
const PageFooterLink: React.FC<Props> = ({ href, children }) => {
	return (
		<li>
			<Link
				href={href}
				className="arrow-link block my-1 sm:hover:underline font-medium"
			>
				{children}
			</Link>
		</li>
	);
};

export default PageFooterLink;
