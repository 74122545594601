/**
 * ImageInfoBlock
 */

import Image from 'components/Image';
import React from 'react';
import { InfoImage } from 'types/global';

interface Props {
	infoBlock: InfoImage;
	id: string;
	openInfoBlock: string;
	handleClick: (id: string) => void;
}

/** small image block component, above carrousel image. */
const ImageInfoBlock: React.FC<Props> = ({
	infoBlock,
	id,
	openInfoBlock,
	handleClick,
}) => {
	const {
		heading,
		posX,
		posY,
		direction,
		teaserImage,
		teaserPosition,
		teaserHorizontalPosition,
		teaserLink,
		teaserLinkText,
		teaserText,
	} = infoBlock;

	return (
		<div
			className="absolute z-20"
			style={{
				left: `${posX.toString()}%`,
				bottom: `${posY.toString()}%`,
			}}
		>
			{openInfoBlock === id && (
				<div style={
					teaserPosition === "bottom" ? {top: "57px", left: teaserHorizontalPosition} : 
					{bottom: "57px", left: teaserHorizontalPosition}
				} className="infobox">
					{teaserImage &&
						<Image 
						src={teaserImage.src} 
						alt={teaserImage.alt} 
						srcSet={teaserImage.srcSet}
						srcSetSizes={teaserImage.srcSetSizes}
						/>					
					}
					<div className="flex flex-col text-white ">
						<div className="flex w-full justify-between pt-1 pl-2">
							<p className="title">{heading}</p>
							<span className="infobox-close" onClick={() => handleClick('')} />
						</div>
						<p className="text">{teaserText}</p>
						{teaserLink && 
							<div className="link-wrapper">
								<a tabIndex={-1} href={teaserLink}>
									{teaserLinkText}
								</a>
							</div>
						}
					</div>
				</div>
			)}
			<button
				aria-label="see more about it"
				className="cursor-pointer focus:outline-none"
				onClick={() => handleClick(id)}
				tabIndex={-1}
			>
				<img
					style={{ transform: `rotate(${direction}deg)` }}
					alt="yellow arrow pointer"
					src={'/assets/images/yellow-arrow-pointer-left.png'}
				/>
			</button>
		</div>
	);
};

export default ImageInfoBlock;
